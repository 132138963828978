.media-tabs .tutorial {
  margin: 15px 10px 14px 0;
  padding: 12px 16px;
  background-color: var(--button-secondary-color);
  font-size: 16px;
  font-weight: 500;
  color: white;
}

.media-tabs .sort-commands {
  margin: 56px 0 30px 0;
}

.media-tabs .sort-commands button {
  margin: 0;
  padding: 0;
  border: none;
}
.media-tabs .sort-commands .divider-vertical {
  margin: 0 12px;
}
.select-option {
  color: var(--text-secondary);
  font-weight: 500;
  font-size: 16px;
  min-width: 150px;
}
.company-list .close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 40px;
  height: 40px;
}

.company-list {
  max-height: 300px;
  overflow-y: scroll;
}

.company-item {
  width: 500px;
  border: solid 1px var(--line-color);
  color: var(--text-secondary);
  font-weight: 500;
  border-radius: 4px;
  height: 50px;
}

.company-item.active {
  border: solid 1px var(--primary-color);
  color: var(--primary-color);
}

.company-item + .company-item {
  margin-top: 16px;
}

@media (max-width: 1134px) {
  .create-ad > div:first-child {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
}

/******* FormMapSelectionBox *******/
/* .create-ad-part .map-tags {
  min-width: calc(60% - 1.5rem);
  border: solid 1px var(--line-color);
  background-color: white;
  padding: 16px;
  overflow-y: auto;
}
.create-ad-part .map-tags div {
  float: left;
  margin-right: 8px;
  margin-bottom: 8px;
  border-radius: 2px;
  border: solid 1px var(--disabled-line);
  background-color: var(--disabled-bg);
  display: flex;
  flex-direction: row;
}
.create-ad-part .map-tags button {
  margin: 0;
  padding: 10px 0;
}
.create-ad-part .map-tags button.action-button {
  padding-left: 8px;
} */
/******* FormMapSelectionBox *******/

/******* MapMultiSelectBox *******/
.map-multiselect-box {
  margin-bottom: 16px;
}
.map-multiselect-box button {
  padding: 12px;
  border-radius: 0;
  margin: 0;
  background-color: white;
  color: var(--text-primary);
  border: solid 1px var(--line-color);
}
.map-multiselect-box button:hover {
  color: var(--button-secondary-color);
}
.map-multiselect-box button.selected {
  border: solid 1px var(--text-primary);
}
.map-multiselect-box .depth-1,
.map-multiselect-box .depth-2,
.map-multiselect-box .depth-3 {
  margin-bottom: 24px;
}
.map-multiselect-box .depth-2,
.map-multiselect-box .depth-3 {
  padding: 16px 0 0 16px;
  border: solid 1px var(--line-color);
}
.map-multiselect-box .depth-2 button {
  float: left;
  margin: 0 16px 16px 0;
  padding: 12px 16px;
}
.map-multiselect-box .depth-3 label.checkbox {
  float: left;
  margin: 0 16px 16px 0;
  font-size: 16px;
}
/******* MapMultiSelectBox *******/

.create-ad-part {
  margin: 0 0 56px;
  padding: 60px;
  border: solid 1px var(--line-color);
  background-color: white;
}
.business-id-check {
  max-width: calc(100% - 120px);
}
@media (max-width: 798px) {
  .create-ad-part {
    padding: 40px;
    margin-bottom: 8px;
    border: none;
  }
}

@media (max-width: 570px) {
  .create-ad-part {
    padding: 24px;
  }
}

@media (max-width: 350px) {
  .create-ad-part {
    padding: 12px;
  }
}
.create-ad-part .map-selector h3 {
  font-size: 15px;
}

@media (max-width: 444px) {
  .map-selector .tab-primary,
  .map-selector .tab-primary-text {
    font-size: 0.8rem;
  }
}

.create-ad-part .map-holder {
  margin-bottom: 20px;
}

.create-ad-part .map-view {
  min-width: 40%;
  flex-shrink: all;
  background-color: var(--bg-color-1);
}

.create-ad-part .calender-note {
  background-color: var(--bg-color-1);
  padding: 28px 32px;
  border-radius: 4px;
  margin-bottom: 20px;
}
.create-ad-part .note {
  background-color: var(--bg-color-1);
  border-radius: 4px;
  padding: 28px 32px;
  min-height: 80px;
  color: var(--text-secondary);
  margin-bottom: 20px;
}
.create-ad-part .note span,
.create-ad-part .calender-note span {
  display: block;
}
.create-ad-part .note span:first-child,
.create-ad-part .calender-note span:first-child {
  font-weight: bold;
}
.create-ad-part .amount {
  padding: 24px 40px;
  border-radius: 4px;
  background-color: #fff4e5;
  font-size: 20px;
  font-weight: bold;
  text-align: left;
  color: var(--text-primary);
}
.create-ad-part .amount button,
.create-ad-part .eval button {
  margin: 0px;
  padding: 0px;
  display: inline-block;
}
@media (max-width: 570px) {
  .create-ad-part .note {
    padding: 20px 16px;
    margin-bottom: 10px;
  }
  .create-ad-part .amount {
    padding: 16px;
    font-size: 15px;
  }
}

.create-ad-part .eval {
  padding: 8px 16px;
}
.create-ad-part .eval span {
  font-size: 18px;
  color: var(--text-secondary);
  font-weight: 500;
}
.create-ad-part .eval span.title {
  font-weight: bold;
}
.create-ad-part .eval-spacer {
  margin-bottom: 30px;
}

.ad-buttons-holder {
  margin-bottom: 100px;
}
.ad-buttons-holder button {
  margin: 0 16px;
}

.create-ad-part .validation {
  margin-bottom: 0;
}

.create-ad-part.error {
  border: solid 1px var(--error-color);
}

.create-ad-part .search-textbox,
.create-ad-part .date-range-box,
.create-ad-part .currency-textbox {
  margin-bottom: 24px;
}

.ad-campaign-list {
  margin-bottom: 64px;
}

.ad-campaign-list {
  margin-bottom: 64px;
}

/**************/

.payment-holder {
  padding: 24px;
  border: solid 1px var(--line-color);
  background-color: var(--line-color);
  margin-bottom: 100px;
}
.payment-content {
  background-color: white;
  padding: 56px 70px;
}
.payment-content section {
  margin-bottom: 40px;
}
.payment-content .actions-section {
  padding-bottom: 100px;
}
.payment-content .actions-section button + button {
  margin-left: 16px;
}
.payment-content h3 {
  padding: 24px 60px;
  margin: 0;
}
.payment-content .label {
  color: var(--text-secondary);
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 8px;
}
.payment-content .value {
  color: var(--text-secondary);
  font-size: 16px;
  font-weight: bold;
}
@media (max-width: 798px) {
  .payment-content {
    padding: 0;
  }
  .payment-holder {
    padding: 8px;
    margin-bottom: 100px;
  }
  .payment-content h3 {
    padding: 16px;
  }
  .payment-content .label,
  .payment-content .value {
    font-size: 14px;
  }
  .payment-content .section-divider {
    height: 8px;
    background-color: var(--disabled-bg);
  }
  .payment-content section {
    margin-bottom: 20px;
  }
  .payment-content .actions-section {
    padding: 16px 16px 40px 16px;
  }
  .payment-content .actions-section button {
    min-height: unset;
    padding: 16px;
    font-size: 12px;
  }
  .payment-content .actions-section button + button {
    margin-left: 0px;
    margin-top: 16px;
  }
}
.payment-content .gray-holder,
.payment-content .orange-holder {
  padding: 40px 60px;
  background-color: var(--line-color);
}
.payment-content .orange-holder {
  background-color: #fff4e5;
}
.payment-content .gray-holder span,
.payment-content .orange-holder span {
  font-size: 28px;
  font-weight: 900;
  text-align: left;
  color: var(--text-primary);
  margin: 0;
}
.payment-content .gray-holder span:last-child,
.payment-content .orange-holder span:last-child {
  font-size: 24px;
}
@media (max-width: 798px) {
  .payment-content .gray-holder,
  .payment-content .orange-holder {
    padding: 24px 12px;
    margin: 0 16px 16px 16px;
  }
  .payment-content .gray-holder span,
  .payment-content .orange-holder span,
  .payment-content .gray-holder span:last-child,
  .payment-content .orange-holder span:last-child {
    font-size: 20px;
  }
}
.payment-content .content-holder {
  padding: 24px 60px;
}
.payment-content .white-holder {
  padding: 24px 60px;
  font-size: 18px;
  font-weight: bold;
  text-align: left;
  color: var(--text-secondary);
}
.payment-content .white-holder button {
  margin: 0px;
  padding: 0px;
  display: inline-block;
}
.payment-content .white-holder-2 {
  padding: 16px 60px;
}
.payment-content .white-holder-3 {
  padding: 8px 60px;
  margin-bottom: 40px;
}
.payment-content .white-holder-4,
.estimate .white-holder-4 {
  padding: 16px 16px 0;
  border: solid 1px var(--line-color);
  background-color: white;
}
.payment-content .white-holder-4 span,
.estimate .white-holder-4 span {
  font-size: 14px;
  padding: 16px;
  padding: 8px 16px;
  border-radius: 2px;
  border: solid 1px var(--line-color);
  background-color: var(--bg-color-1);
  margin-right: 16px;
  margin-bottom: 16px;
  display: inline-block;
}
@media (max-width: 798px) {
  .payment-content .white-holder-2,
  .payment-content .white-holder-3,
  .payment-content .content-holder {
    padding: 16px;
  }
  .payment-content .white-holder-3 {
    margin-bottom: 8px;
  }
  .payment-content .white-holder {
    padding: 3px 29px;
    font-size: 14px;
  }
  .payment-content .white-holder button {
    width: 17.5px;
    height: 17.5px;
    margin: 0px;
    padding: 0px;
    display: inline-block;
  }
}
.payment-content .holder-1 {
  margin: 16px 60px;
}
.payment-content .note {
  background-color: var(--bg-color-1);
  border-radius: 4px;
  padding: 28px 32px;
  min-height: 80px;
  color: var(--text-primary);
  margin: 16px 60px;
}
.payment-content .note span {
  display: block;
}
.payment-content .note span:first-child {
  font-weight: bold;
}
.payment-content .actions {
  margin: 24px 60px;
}
.payment-content .actions button {
  margin-right: 16px;
}
.payment-content .checkboxes {
  margin: 24px 60px;
}
.payment-content .title {
  font-size: 20px;
}
.payment-content .subtitle {
  font-size: 18px;
  font-weight: bold;
  margin: 0;
}
.payment-content .white-holder-2.description p {
  font-size: 18px;
  font-weight: 500;
  text-align: left;
  color: var(--text-tertiary);
  margin-bottom: 40px;
}
.payment-content .accordion-item .accordion-body {
  background-color: white;
}
.payment-content .white-holder-5 {
  padding: 16px 0px;
  font-weight: 500;
  text-align: left;
  color: var(--text-secondary);
  font-size: 18px;
}
.payment-content .white-holder-5 span:last-child {
  color: var(--text-tertiary);
}
@media (max-width: 798px) {
  .payment-content .note {
    padding: 16px;
    margin: 16px;
  }
  .payment-content .holder-1 {
    margin: 0 16px;
  }
  .payment-content .accordion-item .accordion-body {
    padding: 17px;
  }
  .payment-content .bold-text {
    font-size: 14px;
    font-weight: bold;
    color: var(--text-primary);
  }
  .payment-content .gray-text {
    font-size: 14px;
    font-weight: bold;
    color: var(--text-secondary);
  }
  .payment-content .actions {
    margin: 16px;
  }
  .payment-content .checkboxes {
    margin: 16px;
  }
  .payment-content .checkboxes span {
    white-space: pre-wrap;
  }
  .use_point {
    max-width: calc(100% - 80px);
  }
}

@media (max-width: 1134px) {
  .media .container {
    padding: 0;
    padding-top: 30px !important;
  }
  .media-tabs .sort-commands {
    margin: 36px 30px;
  }
}

@media (max-width: 798px) {
  .media .container {
    padding-top: 0px !important;
    max-width: 100%;
    margin: 0;
    border-top: solid 1px var(--line-color);
  }
  .asset-container {
    padding: 30px;
  }
  .media-tabs .sort-commands {
    margin: 20px 12px;
  }
}

@media (max-width: 400px) {
  .media .media-tabs .actions a.button,
  .media .media-tabs .actions button {
    font-size: 12px;
  }
}

.media .skb-fab {
  display: none;
}
@media (max-width: 798px) {
  .media .skb-fab {
    display: block;
  }
}

.notice-box {
  margin: 16px 25px;
  padding: 16px;
  background-color: var(--bg-color-1);
}
.notice-box p {
  margin: 0;
}
.notice-box p:first-child {
  margin-bottom: 8px;
  font-weight: bold;
}

@media (max-width: 798px) {
  .notice-box {
    margin: 12px;
    padding: 16px;
    background-color: var(--bg-color-1);
  }
}

.ad-campaign-layout .loading {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.ad-campaign-layout .dimmed {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #222222;
  opacity: 0.3;
  z-index: 1;
}

.ad-campaign-layout .loading img {
  z-index: 1;
}