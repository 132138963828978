/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
textarea::placeholder,
input::placeholder {
  color: var(--text-tertiary);
}
textarea:-ms-input-placeholder,
input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--text-tertiary);
}
textarea::-ms-input-placeholder,
input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--text-tertiary);
}

input,
p.input {
  border-radius: 4px;
  border: solid 1px var(--disabled-line);
  background-color: white;
  font-weight: 500;
  color: var(--text-secondary);
}

input.larger,
p.input.larger {
  padding: 16px 14px;
  border-radius: 4px;
  font-size: 16px;
}

input.large,
p.input.large {
  padding: 12px 14px;
  border-radius: 4px;
  font-size: 16px;
}

input.medium,
p.input.medium {
  padding: 12px 14px;
  border-radius: 4px;
  font-size: 14px;
}

input.small,
p.input.small {
  padding: 8px 14px;
  border-radius: 2px;
  font-size: 14px;
}

input.smaller,
p.input.smaller {
  padding: 8px 14px;
  border-radius: 2px;
  font-size: 12px;
}

textarea {
  min-height: 80px;
  padding: 9px 4px 4px 12px;
  border-radius: 2px;
  font-size: 16px;
}

input[disabled],
p.input.disabled {
  border: solid 1px var(--disabled-line);
  background-color: var(--disabled-bg);
}

/*          Textbox           */
.validation {
  margin-bottom: 16px;
}
.validation button,
.validation input {
  margin-bottom: 0;
}
.validation small {
  margin-top: 4px;
  font-size: 14px;
  font-weight: 500;
  display: block;
}
.validation small.error {
  color: var(--error-color);
}
.validation small.success {
  color: var(--success-color);
}
.validation small img {
  display: inline-block;
}
.textbox-action button {
  margin-bottom: 0;
  margin-left: 16px;
}
.textbox-action button.reversed {
  margin-bottom: 0;
  margin-right: 16px;
  margin-left: 0;
}

.search-textbox,
.currency-textbox,
.date-range-box,
.date-select-box,
.franchise-calendar {
  position: relative;
}
.search-textbox input,
.currency-textbox input,
.date-range-box input {
  padding: 12px;
  font-size: 14px;
  font-weight: 500;
  width: 100%;
  padding-right: 50px !important;
}
.search-textbox:after,
.date-range-box:after,
.currency-textbox:after,
.franchise-calendar:after {
  z-index: 1;
  position: absolute;
  right: 14px;
  top: 10px;
}
.franchise-calendar.larger:after {
  top: 16px !important;
}
.date-select-box input {
  padding: 16px 14px;
  font-size: 16px;
  font-weight: 500;
  width: 100%;
}
.date-select-box:after {
  z-index: 1;
  position: absolute;
  right: 14px;
  top: 14px;
}
.search-textbox:after {
  content: url("../icons/icon-outlined-application-search-large-text-3.svg");
}
.currency-textbox:after {
  content: "원";
  color: var(--text-tertiary);
  font-size: 14px;
  top: 14px;
}
@media (min-width: 798px) {
  .search-textbox input,
  .currency-textbox input,
  .date-range-box input {
    padding: 16px;
    font-size: 16px;
  }
  .search-textbox:after,
  .date-range-box:after,
  .currency-textbox:after {
    font-size: 20px;
    right: 14px;
    top: 14px;
  } 
}
.date-range-box:after,
.date-select-box:after {
  content: url("../icons/icon-outlined-application-calendar.svg");
}

.search-textbox ul {
  position: absolute;
  width: 100%;
  top: 50px;
  padding: 10px;
  z-index: 10;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
  background-color: white;
  border-radius: 2px;
}
.search-textbox ul li {
  cursor: pointer;
  font-size: 14px;
  padding: 8px 16px;
  font-weight: 500;
  text-align: left;
  color: var(--text-primary);
}
.search-textbox ul li:hover {
  background-color: #fff4e5;
}
.search-textbox ul li:active {
  background-color: #fff4e5;
}

.select {
  border: solid 1px var(--disabled-line);
  padding: 16px 14px;
  font-size: 16px;
  border-radius: 4px;
  background-color: white;
}
.select select {
  outline: none;
}

@media (min-width: 798px) {
  .search-textbox ul {
    top: 80px;
    padding: 10px;
  }
  .search-textbox ul li {
    font-size: 16px;
    padding: 10px 16px;
  }
}

.textbox-wrapper input {
  padding: 14px 10px;
}

.franchise-calendar:after {
  content: url("../icons/icon-outlined-application-calendar-brand.svg");
}