.accordion-item {
  border: none;
}
.accordion-item .accordion-header {
  font-size: 16px;
}
.accordion-item .accordion-header button {
  font-size: 16px;
  color: var(--text-primary);
  margin: 0;
  padding: 28px 16px;
  border-bottom: solid 1px var(--line-color);
}
.accordion-item .accordion-body {
  padding: 24px;
  font-size: 16px;
  font-weight: 500;
  white-space: nowrap;
  color: var(--text-primary);
  background-color: var(--bg-color-1);
}
.accordion-item .accordion-button:not(.collapsed) {
  color: var(--text-brand);
  background-color: white;
  box-shadow: none;
}

.admedia .accordion-item .accordion-button:not(.collapsed) {
  color: var(--brand-color);
  border-bottom: 2px solid var(--text-primary);
}