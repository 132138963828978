.tutorial {
  background-color: white;
}

.tutorial .container {
  margin-top: 60px;
}

.tutorial .container h2 {
  font-size: 18px;
  line-height: 18px;
  margin: 0;
}

.tutorial .window-header {
  padding: 20px 24px;
  border-bottom: solid 2px var(--line-color);
}

.tutorial .menus {
  padding: 26px 41px;
}

@media (min-width: 798px) {
  .tutorial .menus {
    min-width: 200px;
    padding: 32px 16px;
  }
}

@media (min-width: 1134px) {
  .tutorial {
    background-color: var(--bg-color-1);
  }
}

.tutorial-detail {
  padding-bottom: 25px;
}

.tutorial-image-wrapper {
  padding: 25px;
}

.tutorial-button-wrapper {
  padding: 8px 25px;
  background-color: white;
  box-shadow: 0 -3px 5px 0 rgba(0, 0, 0, 0.03);
}

.tutorial .tutorial-content {
  border-left: solid 2px var(--line-color);
  border-right: solid 1px var(--line-color);
}

.tutorial .lg-button-wrapper {
  padding: 16px 64px 16px;
}

.tutorial .video-wrapper {
  background-color: var(--bg-color-1);
  border-bottom: solid 1px var(--line-color);
  padding: 0 16px 16px 16px;
}

.tutorial .video-carousel .arrow-wrapper button {
  padding: 0;
}

.tutorial .carousel-inner {
  /* height: 515px;  */
}

.tutorial-detail .carousel-inner img {
  height: 65vh; 
}