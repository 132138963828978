.navbar {
  background-color: white;
  /*border-bottom: solid 1px var(--line-color);*/
  padding: 0;
}
.navbar * {
  font-family: inherit;
}

.navbar .dropdown-toggle::after {
  display: none;
}

.navbar .container {
  padding: 0 25px;
}

.nav-layout-header {
  padding: 0 25px;
  border-bottom: solid 1px #eeecea;
  min-height: 84px;
  align-items: center;
  font-weight: bold;
  font-family: 'Noto Sans KR';
}

@media (max-width: 798px) {
  .navbar .container {
    max-width: 100%;
  }
}

.navbar-brand {
  margin: 0;
  padding: 24px 0;
}
.navbar-brand img {
  margin: 0;
}
.navbar-light .navbar-toggler {
  margin: 16px 0;
  border: none;
}
.navbar-light .navbar-nav .nav-link {
  color: var(--text-primary);
}
.navbar-light .navbar-nav .nav-link:hover {
  color: var(--primary-color-4);
}
.navbar-light .navbar-nav .nav-link.active {
  color: var(--primary-color);
}
.navbar-light .navbar-nav .nav-franchise.active {
  color: var(--brand-color) !important;
}
.navbar-nav .nav-link {
  transition: 0.3s;
  display: inline-block;
  font-size: 20px;
  font-weight: 500;
  padding: 29px 0 25px;
  position: relative;
}
.navbar-nav .nav-link:before {
  position: absolute;
  content: "";
  border-bottom: 4px solid white;
  left: 0;
  right: 0;
  bottom: 0;
}
.navbar-nav .nav-link.active:before,
.navbar-nav .nav-link[aria-expanded="true"]:before {
  border-bottom: 4px solid var(--primary-color);
}
.navbar-nav .nav-franchise.active:before,
.navbar-nav .nav-franchise[aria-expanded="true"]:before {
  border-bottom: 4px solid var(--brand-color) !important;
}

.navbar-nav .nav-link.navbar-myproj {
  padding-top: 10px;
  height: 60px;
}

.navbar-nav .nav-link.navbar-myproj:after {
  position: absolute;
}

@media (min-width: 1200px) {
  .navbar-nav .nav-link.navbar-myproj {
    padding-top: 29px;
    height: unset;
    padding-right: 110px;
  }
  
  .navbar-nav .nav-link.navbar-myproj:after {
    left: 4px;
  }
}

.navbar-nav .nav-link.navbar-page {
  padding-right: 46px;
}

.navbar-nav .nav-link.navbar-page:after {
  position: absolute;
  padding-left: 10px;
}
.navbar-nav .nav-link.navbar-myproj:after {
  content: url(../icons/icon-outlined-application-folder-header.svg);
  width: 115px;
  height: 35px;
}
.navbar-nav .nav-link.navbar-myproj:hover:after {
  content: url(../icons/icon-outlined-application-folder-header-primary.svg);
}
.navbar-nav .nav-link.active.navbar-myproj:after {
  content: url(../icons/icon-outlined-application-folder-open-header.svg);
}
.navbar-nav .nav-link.navbar-page:after {
  content: url(../icons/icon-outlined-application-user-header.svg);
}
.navbar-nav .nav-link.navbar-page:hover:after {
  content: url(../icons/icon-outlined-application-user-header-primary-2.svg);
}
.navbar-nav .nav-link.active.navbar-page:after {
  content: url(../icons/icon-outlined-application-user-header-primary.svg);
}
.navbar-nav .nav-link.go-back {
  color: var(--text-primary) !important;
  padding-left: 46px;
}
.navbar-nav .nav-link.go-back:before {
  position: absolute;
  border: none;
  left: 0;
  right: auto;
  bottom: auto;
  padding-right: 10px;
  content: url(../icons/icon-outlined-directional-arrow-left.svg);
}

@media (min-width: 798px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    margin-right: 28px;
  }
  .navbar-expand-lg .navbar-nav .nav-link:last-child {
    margin-right: 0;
  }
  .navbar-brand {
    margin-right: 40px;
  }
}

.navbar-nav .dropdown-menu {
  padding: 10px;
  border: none;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
  background-color: white;
}
.dropdown-item {
  transition: 0.3s;
  display: inline-block;
  font-size: 20px;
  font-weight: 500;
  padding: 10px 16px;
  font-size: 18px;
  font-weight: 500;
  position: relative;
  border-radius: 4px;
  color: var(--text-secondary);
}
.dropdown-item.active,
.dropdown-item:active {
  background-color: var(--primary-color);
}

@media(min-width: 1134px) {
  #responsive-navbar-nav .navbar-nav > a+a,
  #responsive-navbar-nav .navbar-nav > .nav-item+a {
    margin-left: 20px;
  }

  #responsive-navbar-nav .navbar-nav .nav-item {
    margin-left: 20px;
  }
}

/* @media (max-width: 1134px) {
  .navbar {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  }
} */