.franchise-card {
  padding: 1.5rem;
  border-radius: 16px;
  border: solid 1px var(--line-color);
  background-color: white;
  overflow: hidden;
}

label.required:before,
span.required:before {
  content: "* ";
  color: var(--error-color);
  font-weight: bold;
}

.divider-primary {
  border-top: 3px solid var(--disabled-line);
  margin-bottom: 24px;
  margin-top: 24px;
}

.divider-secondary {
  position: relative;
  margin: 16px 0;
}
.divider-secondary div:first-child {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
.divider-secondary div:first-child div {
  border-top: 1px solid var(--disabled-line);
  width: 100%;
}
.divider-secondary div:last-child {
  position: relative;
  display: flex;
  justify-content: center;
}
.divider-secondary span {
  padding: 0 24px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.64;
  background-color: white;
  color: var(--text-secondary);
}

.divider-vertical {
  margin: 0 40px;
  border-left: solid 2px var(--line-color);
  flex-grow: 0;
}

.divider-tertiary {
  border-top: solid 1px var(--line-color);
  margin-bottom: 24px;
  margin-top: 24px;
}

.tag {
  display: inline-block;
  padding: 2px 8px 4px;
  border-radius: 2px;
  margin: 0 8px 8px 0;
  border: solid 1px var(--line-color);
  background-color: var(--bg-color-1);
  cursor: pointer;
  transition: background-color 0.3s;
}
.tag:hover {
  background-color: var(--bg-color-2);
}

.steps {
  position: relative;
}
.steps button {
  margin: 0;
  padding: 0;
}
.steps div.step {
  position: relative;
  align-items: center;
  background-color: white;
  border: solid 1px var(--line-color);
  padding: 24px;
  height: 100%;
}

.steps div.step h2 {
  color: var(--text-secondary);
  line-height: 1.58;
  margin-bottom: 0px;
}
.steps div.step h2 span {
  vertical-align: bottom;
  line-height: 1.2;
  font-size: 15px;
  padding: 4px;
  text-align: center;
  width: 35px;
  height: 35px;
  margin-right: 8px;
  display: inline-block;
  border: solid 4px var(--text-secondary);
  background-color: white;
  border-radius: 100%;
}
.steps div.step p {
  margin: 8px 0 0 43px;
  font-size: 18px;
  font-weight: 500;
  text-align: left;
  color: var(--text-tertiary);
}

.steps div.step.selected {
  border: solid 1px var(--primary-color);
}
.steps div.step.selected * {
  color: var(--primary-color);
}
.steps div.step.selected h2 span {
  border: solid 4px var(--primary-color);
}

@media (max-width: 798px) {
  .steps div.step {
    padding: 16px;
  }
  .steps div.step h2 {
    color: var(--text-secondary);
    line-height: 1.58;
    font-size: 14px;
  }
  .steps div.step h2 span {
    vertical-align: bottom;
    line-height: 1.2;
    font-size: 11px;
    padding: 1px;
    text-align: center;
    width: 20px;
    height: 20px;
    margin-right: 8px;
    display: inline-block;
    border: solid 2px var(--text-secondary);
    background-color: white;
    border-radius: 100%;
  }
  .steps div.step.selected h2 span {
    border: solid 2px var(--primary-color);
  }
}

.maps {
  position: relative;
  width: 100%;
  height: 100%;
}

.progress {
  background-color: var(--line-color);
  overflow: visible;
  border-radius: 10px;
}
.progress-bar {
  border-radius: 10px;
  overflow: visible;
  background-color: var(--primary-color);
  font-size: 18px;
  padding-top: 14px;
  justify-content: end;
  font-weight: bold;
  color: var(--text-primary);
}

.info-paper {
  margin: 100px 0;
  padding: 48px 60px;
  background-color: var(--line-color);
  border-radius: 50px 50px 0px 50px;
}
.info-paper img {
  margin: 0 50px 0 0;
  width: 100%;
  max-width: 150px;
  max-height: 150px;
}
.info-paper p.main {
  margin: 17px 0 16px 0px;
  font-size: 24px;
  font-weight: bold;
  text-align: left;
  color: var(--text-primary);
}
.info-paper p.sub {
  color: var(--text-secondary);
}
.info-paper button {
  margin-top: 32px;
}

.banner,
.banner-2 {
  background-color: #f7f6f5;
  border-radius: 40px;
  border-bottom-right-radius: 0;
}
.banner .carousel-item {
  min-height: auto;
  padding: 45px 60px;
}
.image-banner .carousel-item {
  min-height: none;
  padding: 0;
}
.banner h3,
.banner-2 h3 {
  font-size: 24px;
  font-weight: bold;
  line-height: 1.58;

  color: var(--text-primary);
}
.banner p,
.banner-2 p {
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  color: var(--text-secondary);
}
.banner .carousel-indicators {
  justify-content: flex-end;
  margin-right: 5%;
}

.banner .carousel-indicators button {
  background-color: var(--bg-color-2);
  height: 10px;
  width: 10px;
  border-radius: 100%;
}
.banner .carousel-indicators button.active {
  background-color: var(--primary-color);
  width: 32px;
  border-top: 0;
  border-bottom: 0;
  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.banner-2 .carousel-item .content {
  padding: 45px 60px;
}
.banner-2 .actions {
  margin: 0 60px;
  padding-bottom: 35px;
}
.banner-2 .controls button,
.banner-2 .progress-view button {
  margin: 0;
  padding: 0;
  margin-right: 8px;
}
.banner-2 .controls button img,
.banner-2 .progress-view button img {
  margin: 0;
}
.banner-2 .indicators span {
  font-size: 14px;
  font-weight: bold;
  line-height: 1.57;
  text-align: left;
  color: var(--text-tertiary);
  margin-right: 16px;
}
.banner-2 .indicators span.active {
  color: var(--primary-color);
}
.banner-2 .progress-view {
  margin-right: 48px;
}
.banner-2 .progress {
  margin-right: 16px;
  width: 48px;
  height: 4px;
}
.banner-2 .progress .progress-bar {
  padding: 0px;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  animation: 1.5s linear infinite spinner;
}

.table {
  font-size: 14px;
  line-height: 1.57;
  text-align: left;
  color: var(--text-secondary);
}
.table thead tr th,
.table .table-header-group .table-row .table-cell {
  padding: 20px 16px;
  box-shadow: 0 1px 0 0 #e8e8e8;
  background-color: #fafafa;
  font-weight: bold;
  border-bottom: solid 0px var(--line-color);
}
.table tbody tr td,
.table .table-row .table-cell {
  padding: 20px 16px;
  box-shadow: 0 1px 0 0 #e8e8e8;
  border-bottom: solid 0px var(--line-color);
}

.table tbody tr.table-empty td {
  height: 400px;
  text-align: center;
  vertical-align: middle;
  font-size: 18px;
  font-weight: bold;
  color: var(--text-primary);
}

.notices.franchise-card .table tr th,
.notices.franchise-card .table tr td {
  padding: 14px 8px;
}

.badge {
  font-size: 0.7rem;
  vertical-align: top;
}

.tooltip-inner {
  background-color: #999;
  max-width: none;
  font-size: 18px;
  font-weight: bold;
  padding: 12px;
  border-radius: 4px;
  white-space: pre-wrap;
  width: fit-content;
}
.tooltip-arrow::before {
  border-top-color: #999 !important;
}
.bs-tooltip-bottom .tooltip-arrow::before {
  border-bottom-color: var(--primary-color);
}

.video-carousel .indicators {
  margin-top: 16px;
}
.video-carousel .indicators span {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin: 0 10px;
  background-color: var(--bg-color-2);
  border-radius: 4px;
}
.video-carousel .indicators span.active {
  width: 32px;
  height: 8px;
  margin: 0 10px 0 0;
  border-radius: 4px;
  background-color: var(--primary-color);
}
.video-carousel .arrow-wrapper {
  min-width: 80px;
}

.skb-fab {
  position: fixed;
  right: 20px;
  bottom: 30px;
}

.skb-fab img {
  width: 54px;
  height: 54px;
}

@media (max-width: 1134px) {
  .container {
    padding: 0;
    padding-top: 30px !important;
  }
}

@media (max-width: 798px) {
  .container {
    padding-top: 0px !important;
    max-width: 100%;
    margin: 0;
  }
}

.auth-paper .bottom-wrapper {
  margin-top: 24px;
}

.empty {
  padding: 180px 0;
  border: solid 1px var(--line-color);
  background-color: white;
}
.franchise-empty {
  padding: 100px 0;
  /* border: solid 1px var(--line-color); */
  background-color: white;
}

.franchise-empty .fir,
.empty .fir {
  font-size: 28px;
  font-weight: 900;
  line-height: 1.57;
  text-align: left;
  color: var(--text-primary);
  text-align: center;
}
.franchise-empty .sec,
.empty .sec {
  opacity: 0.72;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.63;
  text-align: center;
  color: var(--text-primary);
}

.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ff9000'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.admedia .accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%233618CE'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.admedia .accordion {
  border-top: solid 1px var(--line-color);
}

#admedia-header-container {
  padding-top: 0 !important;
}