div.page-headline {
  display: block;
  min-height: 196px;
  padding: 72px 0;
  background-color: var(--primary-color);
  width: 100%;
}
div.page-content {
  background-color: white;
  width: 100%;
  flex-grow: 1;
}
div.page-headline > div,
div.page-content > div {
  /*@apply container mx-auto;*/
}
div.page-content > div:first-child {
  padding-top: 56px;
  padding-bottom: 56px;
}

div.page-headline * {
  color: white;
}
div.page-headline h1 {
  font-size: 2.5rem;
  line-height: 1.7;
  font-weight: 900;
  margin: 0;
}
div.page-headline p {
  margin-top: 16px;
}

@media (max-width: 1134px) {
  div.page-headline {
    display: none;
  } 
}