.story-snippet {
  margin: 0;
  padding: 0;
}
.story-snippet img {
  margin: 0;
  margin-bottom: 16px;
}
.story-snippet p {
  font-size: 18px;
  font-weight: bold;
  line-height: 1.61;
  letter-spacing: normal;
  text-align: left;
  color: var(--text-1);
}

.success-story h2 {
  margin-bottom: 40px;
}
.success-story h3 {
  margin-bottom: 24px;
}

.success-story .section-1 {
  min-height: 400px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.success-story .section-1 h1 {
  font-size: 40px;
  font-weight: 900;
  color: white;
  text-align: center;
}

.success-story .section-2,
.success-story .section-4,
.success-story .section-7 {
  padding: 150px 0;
}

.success-story .section-3 {
  padding: 100px 0;
}
.success-story .section-3 p.subtitle {
  margin-bottom: 80px;
}
.success-story .section-3 img {
  padding: 38px 35.6px 39px 36px;
  object-fit: contain;
  border-radius: 31px;
  box-shadow: 0 3px 15px 0 rgba(195, 110, 0, 0.17);
  background-color: white;
  margin: 0 100px 16px;
}
.success-story .section-3 p.pr {
  font-size: 24px;
  font-weight: bold;
  line-height: 1.58;
  color: var(--primary-color);
}

.success-story .section-5 {
  padding: 150px 0 50px;
  background-color: var(--bg-color-1);
}

.success-story .section-6 {
  padding: 50px 0 150px;
  background-color: var(--bg-color-1);
}

.success-story .section-6 p {
  margin-bottom: 80px;
}
.success-story .section-6 .car {
  height: 638px;
}
.success-story .section-6 .carousel {
  display: flex;
  flex-direction: column-reverse;
}
.success-story .section-6 .carousel-indicators {
  position: relative;
}

.success-story .section-6 .carousel-indicators button {
  background-color: var(--bg-color-2);
  height: 15px;
  width: 15px;
  border-radius: 100%;
  position: relative;
  margin: 0 5px;
  display: inline-block;
}

.success-story .section-6 .carousel-indicators button.active {
  background-color: var(--primary-color);
  width: 32px;
  height: 15px;
  align-items: center;
  display: flex;
  align-items: center;
  border-radius: 50%/100%;
  display: inline-block;
}

.success-story .section-7 {
  background-color: white;
}

.success-story .section-8 {
  padding: 100px 0;
  background-color: white;
}

.success-story .use-case {
  padding: 60px;
  background-color: #fff4e5;
  border-radius: 40px;
  border-bottom-right-radius: 0;
}

.success-story .use-case img {
  width: 229px;
  height: 229px;
  background-color: var(--bg-color-2);
  border-radius: 50%;
}
.success-story .use-case div.content {
  padding: 20px 40px 0;
}
.success-story .use-case span.company {
  font-size: 16px;
  font-weight: bold;
  color: var(--primary-color);
  margin-right: 4px;
}
.success-story .use-case span.name {
  color: var(--text-color-3);
  margin-right: 4px;
}
