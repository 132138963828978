h1,
h2,
h3,
h4,
h5,
h6,
caption,
p,
label {
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: var(--text-primary);
  margin-bottom: 16px;
  font-weight: 500;
}

h1,
h2,
h3,
h4,
h5,
h6,
caption,
label {
  font-weight: bold;
}

h1 {
  font-size: 1.75rem;
}

h2 {
  font-size: 1.5rem;
}

h3,
p.headline {
  font-size: 1.25rem;
}

h4,
p,
button,
a,
label,
li {
  font-size: 1rem;
}

caption,
p.small,
button.small,
a.small {
  font-size: 0.87rem;
}

p.smaller,
button.smaller,
a.smaller {
  font-size: 0.75rem;
}

label {
  display: block;
}