.modal-backdrop {
  background-color: var(--bg-color-2);
}
.modal-content {
  border: none;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  max-height: 90vh;
  overflow-y: hidden;
}
.modal-header {
  padding: 24px;
}
.modal-header .btn-close {
  background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23000%27%3e%3cpath d=%27M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z%27/%3e%3c/svg%3e');
}
.modal-body {
  padding: 0;
  max-height: 100%;
  overflow-y: scroll;
}
/* .modal-body > div:first-child {
  max-height: 603.6px;
  overflow-y: scroll;
  padding-bottom: 90px;
} */
/* .modal-body .cta-button {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
} */
.modal-title {
  flex-grow: 1;
  text-align: center;
}

.progress-list {
  padding-left: 10px;
  align-self: start;
}
.progress-list li {
  list-style-type: disc;
  margin-top: 5px;
}

/* .modal-content-transparent .modal-content {
  background-color: transparent;
  overflow-y: visible;
} */