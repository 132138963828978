.react-select-shadow__control {
  padding: 12px !important;
  border: none !important;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
  border-radius: 8px !important;
}

.react-select-shadow__value-container {
  font-weight: 700;
}

.react-select-shadow__menu {
  margin-top: 12px !important;
  z-index: 20 !important;
  border-radius: 8px !important;
}

.react-select-shadow__menu-list {
  padding: 16px !important;
}

/* 자식 선택자 위아래 간격 12px, 첫번째 요소는 아래만 12px, 마지막 요소는 위만 12px */
.react-select-shadow__option:not(:first-child) {
  margin-top: 12px !important;
}

.react-select-shadow__option {
  padding: 16px !important;
  border-radius: 8px !important;
  font-weight: 500 !important;
  color: var(--text-secondary) !important;
  background-color: #f0f0f0 !important;
}

.react-select-shadow__option--is-selected {
  background-color: var(--brand-color) !important;
  color: white !important;
  font-weight: 700 !important;
}