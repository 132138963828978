button,
input[type="button"],
span.button,
a.button {
  display: block;
  cursor: pointer;
  text-align: center;
  padding: 16px 24px;
  border-radius: 4px;
  transition: 0.3s;
}
a.button {
  display: flex;
  align-items: center;
  justify-content: center;
}
/**/
button.brand,
input[type="button"].brand,
span.button.brand,
a.button.brand {
  color: white;
  background-color: var(--brand-color);
  border: 1px solid var(--brand-color);
}
button.brand:hover,
input[type="button"].brand:hover,
span.button.brand:hover,
a.button.brand:hover {
  border: 1px solid var(--brand-hover);
  background-color: var(--brand-hover);
}
button.brand:active,
input[type="button"].brand:active,
span.button.brand:active,
a.button.brand:active {
  border: 1px solid var(--brand-pressed);
  background-color: var(--brand-pressed);
}
button.primary,
input[type="button"].primary,
span.button.primary,
a.button.primary {
  color: white;
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
}
button.primary:hover,
input[type="button"].primary:hover,
span.button.primary:hover,
a.button.primary:hover {
  border: 1px solid var(--primary-hover);
  background-color: var(--primary-hover);
}
button.primary:active,
input[type="button"].primary:active,
span.button.primary:active,
a.button.primary:active {
  border: 1px solid var(--primary-pressed);
  background-color: var(--primary-pressed);
}
/**/
button.secondary,
input[type="button"].secondary,
span.button.secondary,
a.button.secondary {
  color: var(--primary-color);
  background-color: white;
  border: 1px solid var(--primary-color);
}
button.secondary:hover,
input[type="button"].secondary:hover,
span.button.secondary:hover,
a.button.secondary:hover {
  color: var(--primary-hover);
  border: 1px solid var(--primary-hover);
}
button.secondary:active,
input[type="button"].secondary:active,
span.button.secondary:active,
a.button.secondary:active {
  color: var(--primary-pressed);
  border: 1px solid var(--primary-pressed);
}
/**/
button.light,
input[type="button"].light,
span.button.light,
a.button.light {
  color: var(--button-secondary-color);
  background-color: white;
  border: 1px solid var(--line-color);
}
button.light:hover,
input[type="button"].light:hover,
span.button.light:hover,
a.button.light:hover {
  color: var(--text-tertiary);
  border: 1px solid var(--bg-color-2);
}
button.light:active,
input[type="button"].light:active,
span.button.light:active,
a.button.light:active {
  color: var(--text-primary);
  border: 1px solid var(--line-color);
}
/**/
button.dark,
input[type="button"].dark,
span.button.dark,
a.button.dark {
  color: white;
  background-color: var(--button-secondary-color);
  border: 1px solid var(--button-secondary-color);
}
button.dark:hover,
input[type="button"].dark:hover,
span.button.dark:hover,
a.button.dark:hover {
  border: 1px solid var(--text-tertiary);
  background-color: var(--text-tertiary);
}
button.dark:active,
input[type="button"].dark:active,
span.button.dark:active,
a.button.dark:active {
  border: 1px solid var(--text-primary);
  background-color: var(--text-primary);
}
/**/
button.larger,
input[type="button"].larger,
span.button.larger,
a.button.larger {
  font-size: 18px;
  font-weight: 500;
  min-height: 74px;
}
button.large,
input[type="button"].large,
span.button.large,
a.button.large {
  font-size: 16px;
  font-weight: 500;
  min-height: 56px;
}
button.medium,
input[type="button"].medium,
span.button.medium,
a.button.medium {
  font-size: 16px;
  font-weight: 500;
  min-height: 48px;
}
button.medium:hover,
input[type="button"].medium:hover,
span.button.medium:hover,
a.button.medium:hover {
  color: white;
}
/**/
button[disabled],
button[disabled]:hover,
button[disabled]:active,
input[type="button"][disabled],
input[type="button"][disabled]:hover,
input[type="button"][disabled]:active,
span.button.disabled,
span.button.disabled:hover,
span.button.disabled:active,
a.button.disabled,
a.button.disabled:hover,
a.button.disabled:active {
  color: var(--disabled-text);
  border: solid 1px var(--disabled-line);
  background-color: var(--disabled-bg);
  cursor: auto;
  transition: 0;
}

button.naver {
  background-color: #22be34;
  color: white;
  border: 1px solid #22be34;
}

button.kakao {
  background-color: #fae300;
  color: var(--black);
  border: 1px solid #fae300;
}

button.google {
  background-color: #eeeeee;
  color: var(--black);
  border: 1px solid #eeeeee;
}

button img,
a img {
  display: inline-block;
  margin: 0 8px;
  vertical-align: sub;
}

button.right-icon img,
a.right-icon img {
  margin-right: 0;
  margin-bottom: -2px;
}
button.left-icon img,
a.left-icon img {
  margin-left: 0;
}

.button-group {
  border: solid 1px var(--line-color);
  background-color: white;
}
.button-group div {
  display: inline-block;
}
.button-group button,
.button-group button:hover,
.button-group button:active {
  display: inline-block;
  margin: 0;
  border: 0;
}
.button-group button.active {
  color: var(--text-primary);
  font-weight: bold;
}
.button-group span {
  margin: 16px 16px;
  border-right: 1px solid var(--line-color);
}
.button-group div:last-child span {
  border: 0;
}
