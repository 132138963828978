.tab-primary {
  font-size: 12pt;
}

.tabs-primary .tab-list-primary {
  border-bottom: solid 1px var(--line-color);
  margin-bottom: 24px;
}

.tabs-primary .tab-list-primary .tab-primary {
  margin-right: 0px;
  text-align: center;
  cursor: pointer;
  display: inline-block;
  border-bottom: 4px solid transparent;
  transition: 0.3s;
  line-height: 60px;
}

.tabs-primary.large .tab-list-primary .tab-primary {
  margin-right: 0;
}

.tabs-primary .tab-list-primary .tab-primary.active {
  color: var(--primary-color);
  border-bottom: 4px solid var(--primary-color);
}

.tabs-secondary {
  font-size: 0.8rem;
  font-weight: bold;
  background-color: white;
}

.tabs-secondary .tab-list-secondary {
  margin-bottom: 24px;
  border-bottom: 1px solid var(--line-color);
}

.tabs-secondary {
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.tabs-secondary::-webkit-scrollbar {
  display: none;
}

.tabs-secondary .tab-list-secondary .tab-secondary {
  display: inline-block;
  position: relative;
  cursor: pointer;
  white-space: nowrap;
  padding: 27px 16px 15px 16px;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.2;
  text-align: center;
  color: var(--text-secondary);
  border-radius: 0;
  border-bottom: solid 1px var(--line-color);
}

.tabs-secondary .tab-list-secondary .tab-secondary.active:after {
  border-bottom: solid 1px var(--line-color);
}

.tabs-secondary .tab-list-secondary .tab-secondary.active {
  color: var(--primary-color);
  font-weight: bold;
  border-bottom: solid 1px var(--primary-color);
}

@media (max-width: 798px) {
  .tab-primary {
    flex: 1;
  }
}

@media (min-width: 798px) {
  .tab-primary {
    font-size: 1rem;
  }
  .tabs-primary .tab-list-primary .tab-primary {
    margin-right: 32px;
  }
  .tabs-secondary .tab-list-secondary {
    margin-top: 20px;
  }
  .tabs-secondary .tab-list-secondary .tab-secondary {
    font-size: 16px;
    font-weight: bold;
  }
}

@media (min-width: 1134px) {
  .tabs-secondary .tab-list-secondary {
    border-bottom: solid 1px var(--primary-color);
  }
  .tabs-secondary .tab-list-secondary .tab-secondary {
    background-color: white;
    margin-bottom: -1px;
    padding: 24px 36px;
    font-size: 20px;
    border-bottom: solid 1px var(--primary-color);
  }
  .tabs-secondary .tab-list-secondary .tab-secondary.active {
    color: var(--primary-color);
    font-weight: bold;
    border: solid 1px var(--primary-color);
    border-bottom: none;
  }
  .tabs-secondary .tab-list-secondary .tab-secondary.active:after {
    border-bottom: none;
  }
}