@media (max-width: 1134px) {
  .my-page {
    padding-top: 16px;
  }
  .my-page .content {
    width: 100%;
  }
  .my-page-headline {
    padding: 24px 0 !important;
  }
  .my-page-headline h1 span {
    font-size: 28pt !important;
  }
}

@media (max-width: 794px) {
  .my-page-headline .container,
  .my-page .container {
    padding: 0 100px !important;
  }
  .my-page .header {
    display: none;
  }

  .setting-page .textinput-with-button {
    max-width: calc(100% - 120px);
  }
}

@media (max-width: 576px) {
  .my-page-headline .container,
  .my-page .container {
    padding: 0 25px !important;
  }
  .my-page-headline {
    min-height: 150px !important;
    padding: 16px 0 !important;
  }
}
.my-page .side-menu {
  min-width: 185px;
}

.my-page .content {
  margin-left: 65px;
  margin-bottom: 170px;
}
@media (max-width: 794px) {
  .my-page .content {
    width: 100%;
    margin-left: 0px;
    margin-bottom: 30px;
  }
}
.my-page .content .header {
  margin-top: 16px;
}

.my-page .gray-holder {
  margin-bottom: 29px;
  padding: 16px;
  border-radius: 4px;
  background-color: var(--bg-color-1);
}
.my-page .gray-holder span:first-child {
  font-size: 14px;
  font-weight: bold;
  text-align: left;
  color: var(--text-tertiary);
  margin-bottom: 16px;
}
.my-page .gray-holder span:last-child {
  text-align: left;
  color: var(--text-primary);
  font-size: 18px;
  font-weight: bold;
}
.my-page .field-holder {
  margin-bottom: 16px;
}
.my-page .field-holder span:first-child {
  font-size: 16px;
  font-weight: bold;
  color: var(--text-primary);
  margin-bottom: 16px;
}
.my-page .field-holder span:last-child {
  margin-bottom: 16px;
  padding: 16px 14px;
  border-radius: 4px;
  border: solid 1px var(--disabled-line);
  background-color: var(--bg-white);
}

@media (max-width: 798px) {
  .tab-primary {
    flex: 1;
  }
}

/* 모바일 테이블 대체 아이템 */
.my-page .table-mobile-items {
  font-size: 12px;
  border-bottom: solid 1px var(--line-color);
  padding: 15px 0;
}

.my-page .table-mobile-items span {
  font-size: 12px;
  font-weight: 500;
}

.my-page .table-mobile-items > div + div {
  margin-top: 10px;
}

@media (max-width: 794px) {
  .my-page .list-section .tabs-primary {
    margin-left: -25px;
    margin-right: -25px;
  }

  .my-page .list-section .tab-primary {
    flex: 1;
  }

  .my-page .table-mobile-items {
    padding: 15px 25px;
  }

  .my-page .list-section .list-header {
    padding: 0 25px;
  }
}

/* 쿠폰, 포인트 페이지 */
.coupon-page, .point-page {
  margin-top: -16px;
}

.coupon-page .coupon-items > div + div {
  margin-top: 10px;
}

.coupon-page .register_coupons {
  padding-top: 40px;
}

.point-page .divider,
.coupon-page .divider {
  width: 100%;
  height: 4px;
  background-color: var(--line-color);
  margin: 24px 0;
}

@media (max-width: 794px) {
  .point-page .point-box,
  .coupon-page .register_coupons {
    margin: 0 -25px 0 -25px;
    padding-top: 0px;
  }

  .coupon-page .register_coupons .form-wrapper {
    padding: 25px;
  }

  .point-page .divider,
  .coupon-page .divider {
    margin: 0;
  }

  .coupon-page table {
    display: none;
  }

  .coupon-page #register_coupons {
    max-width: calc(100% - 165px);
  }
}

/* 포인트 페이지 */
.point-page .point-box-section {
  padding: 16px 0;
}
.point-page .point-box-section > div {
  padding: 24px;
  border: solid 1px var(--line-color);
  border-right-width: 0px;
  min-width: 194px;
}
.point-page .point-box-section > div:last-child {
  border-right-width: 1px;
}
.point-page .point-box-section span.title {
  font-size: 16px;
  font-weight: bold;
  text-align: left;
  color: var(--text-primary);
  margin-bottom: 32px;
}
.point-page .point-box-section span.value {
  font-size: 20px;
  font-weight: bold;
}
.point-page .point-box-section span.unit {
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  color: var(--text-tertiary);
}
.point-page .pls {
  color: var(--success-color);
}
.point-page .mns {
  color: var(--primary-color);
}
.point-page .border-holder {
  margin-bottom: 24px;
}
.point-page .border-holder button {
  padding: 12px;
  border-radius: 0;
  background-color: white;
  color: var(--text-primary);
  border: solid 1px var(--line-color);
}
.point-page .border-holder button:hover {
  color: var(--button-secondary-color);
}
.point-page .border-holder button.active {
  border: solid 1px var(--text-primary);
}

@media (max-width: 794px) {
  .point-page .point-box-section {
    padding: 16px 24px;
  }
  .point-page .point-box-section > div {
    padding: 16px 9px;
    min-width: 108px;
  }
  .point-page table {
    display: none;
  }
  .point-page .point-box-section .mobile-wrapper span {
    font-size: 11px !important;
    font-weight: bold;
  }
}

@media (max-width: 570px) {
  .point-page .point-box-section span.title {
    font-size: 11px;
    margin-bottom: 17px;
  }
  .point-page .point-box-section span.value {
    font-size: 15px;
  }
  .point-page .point-box-section span.unit {
    font-size: 14px;
  }
  .point-page .list-section ul {
    font-size: 11px;
  }
}


/* 나의 비즈니스 관리  */
.business-page table td {
  font-weight: 500;
  vertical-align: middle;
}

.business-page .request-wrapper {
  background-color: var(--bg-color-1);
  border-radius: 4px;
  padding: 27px 33px;
  font-weight: 500;
}

.business-page .request-wrapper + .request-wrapper {
  margin-top: 16px;
}

.business-page .request-wrapper > span:first-child {
  color: var(--text-secondary);
  font-weight: bold;
}

.business-page .request-wrapper button,
.business-page .table-button {
  white-space: nowrap;
  margin-left: 16px;
  padding: 10px 16px;
  font-weight: 500;
  color: var(--text-secondary);
}

.business-page .divider {
  height: 2px;
  background-color: var(--bg-color-2);
  margin: 44px 0;
}

.business-page .close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 40px;
  height: 40px;
}

.business-page .find-user-info span + span {
  margin-top: 8px;
}

@media (max-width: 794px) {
  .business-page table {
    display: none;
  }
  .business-page .request-wrapper button,
  .business-page .table-button {
    white-space: nowrap;
    font-size: 12px;
    padding: 6px 10px;
  }
  .business-page .request-wrapper button + button {
    margin-top: 8px;
  }
  .business-page .divider {
    margin: 30px -24px;
  }
}

@media (max-width: 570px) {
  .business-page .request-wrapper {
    border-radius: 4px;
    padding: 16px;
  }
  .business-page .request-wrapper > span:first-child {
    white-space: pre-wrap;
  }
}

/* 결제 내역 */
.invoice-page .accordion span {
  font-size: 12px;
  font-weight: 500;
  color: var(--text-secondary);
}

.invoice-page .accordion .accordion-button,
.invoice-page .accordion .accordion-body {
  padding: 15px 25px;
}

.invoice-page .line-item + .line-item {
  margin-top: 10px;
}

@media (max-width: 794px) {
  .invoice-page table {
    display: none;
  }
}

@media(max-width: 570px) {
  .invoice-page {
    margin: -16px -25px 0 -25px;
  }
}