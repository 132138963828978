.menu {
  padding: 0;
  margin: 0;
  background-color: white;
}
.menu > * {
  padding: 0;
  margin: 0;
  text-align: left;
  display: block;
  font-weight: bold;
  transition: 0.3s;
}
.menu button.action,
.menu span.group,
.menu a.link {
  padding: 8px 34px 8px 16px;
}
.menu span.header,
.menu div.parent span.parent-header {
  height: 58px;
  padding: 16px;
  font-size: 18px;
  color: var(--text-primary);
}
.menu span.group {
  height: 36px;
  font-size: 14px;
  color: var(--text-secondary);
}
.menu button.action,
.menu a.link {
  height: 56px;
  font-size: 16px;
  color: var(--text-primary);
}
.menu a.link {
  display: inline-flex;
  align-items: center;
}
.menu button.action:hover,
.menu button.action.active,
.menu a.link:hover,
.menu a.link.active {
  background-color: #fff4e5;
  color: var(--primary-color);
}
.menu .space {
  padding-top: 26px;
}