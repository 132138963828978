.home {
}

/*********************/

.home .icon-paper {
  padding: 30px;
  background-color: white;
  border-radius: 16px;
  min-width: 214px;
  margin-bottom: 16px;
}
.home .icon-paper img {
  margin-bottom: 16px;
}
.home .icon-paper:last-child {
  margin-right: 0;
}
@media (min-width: 992px) {
  .home .icon-paper {
    margin-right: 20px;
    margin-bottom: 0px;
  }
}

/*********************/

.home .video-paper {
  border-bottom-left-radius: 40px;
  border: solid 1px var(--line-color);
  background-color: white;
  box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.04);
}
.home .video-paper .content {
  padding: 22px 22px 49px;
}
.home .video-paper .content .tags {
  margin-bottom: 16px;
}
.home .video-paper .content .tags span {
  font-size: 14px;
  margin-right: 8px;
  padding: 2px 8px;
  border-radius: 2px;
  border: solid 1px var(--line-color);
  background-color: var(--icon-color-1);
}
.home .video-paper .content .tags span:last-child {
  background-color: #e4fd7b;
}
.home .video-paper .content p {
  color: var(--text-secondary);
}

/*********************/

.home .video-toggle p,
.home .video-toggle h3 {
  font-size: 18px;
  font-weight: bold;
  line-height: 1.83;
  text-align: left;
}
.home .video-toggle h3 {
  margin-bottom: 32px;
}
.home .video-toggle p {
  color: var(--primary-color);
}
.home .video-toggle .banner-buttons button {
  border-radius: 30px;
  border-bottom-right-radius: 0;
  padding-left: 30px;
  padding-right: 30px;
  background-color: white;
  white-space: nowrap;
  font-size: 18px;
  font-weight: bold;
  line-height: 2.11;
  text-align: start;
  width: 100%;
  border: none;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}
.home .video-toggle .banner-buttons button.active {
  background-color: var(--primary-color);
  color: white;
  box-shadow: 0 0 0 0 rgb(0 0 0 / 44%);
}
.home .video-toggle .tab-buttons {
  margin-bottom: 16px;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.home .video-toggle .tab-buttons::-webkit-scrollbar {
  display: none;
}
.home .video-toggle .tab-buttons button {
  box-sizing: border-box;
  white-space: nowrap;
  padding: 10px 10px 15px 10px;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.2;
  text-align: center;
  border-radius: 0;
  border-bottom: solid 1px var(--line-color);
}
.home .video-toggle .tab-buttons button.active {
  color: var(--primary-color);
  font-weight: bold;
  border-bottom: solid 3px var(--primary-color);
}
.home .video-toggle .video-wrapper {
    width: 100%;
    align-items: center;
  }
.home .video-toggle video {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  width: 100%;
}
@media (min-width: 992px) {
  .home .video-toggle h3 {
    margin-bottom: 84px;
  }
  .home .video-toggle p,
  .home .video-toggle h3 {
    font-size: 24px;
  }
}
@media (max-width: 570px) {
  .home .video-toggle .video-wrapper {
    height: 59.1vw;
  }
}
/*********************/

.home .white-paper {
  padding: 43px 0 0;
  border-radius: 40px;
  background-color: white;
  box-shadow: 0 3px 50px 0 #f4ebe1;
  overflow: hidden;
}
.home .white-paper div {
  padding: 0 33px;
}
.home .white-paper h3 {
  font-size: 18px;
  font-weight: bold;
  line-height: 1.72;
  text-align: left;
  color: var(--text-primary);
}
.home .white-paper p {
  font-size: 12px;
  font-weight: bold;
  line-height: 2.5;
  text-align: left;
  color: var(--primary-color);
}
@media (min-width: 992px) {
  .home .white-paper h3 {
    font-size: 24px;
  }
  .home .white-paper p {
    font-size: 16px;
  }
}
/*********************/
.home .section-01 {
  height: 80vw;
}
.home .section-01 .background-image {
  /*background-image: url("../../assets/imgs/9400@3x.jpg");*/
  background-repeat: no-repeat;
  background-attachment: local;
  background-position: center;
  background-size: cover;
  height: 80vw;
}
.home .section-01 h1 {
  font-weight: 900;
  line-height: 1.72;
  color: white;
  text-align: left;
  text-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  /* font-size: 24px; */
}
.home .section-01 .link-region {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 15%;
  right: 15%;
}
@media (max-width: 570px) {
  .home .section-01 .container {
    padding-top: 56px;
    left: 5%;
    right: 5%;
    top: 0;
    bottom: 0;
  }
}
@media (min-width: 570px) {
  /* .home .section-01,
  .home .section-01 .background-image {
    height: 676px;
  } */
  /* .home .section-01 h1 {
    font-size: 4vw;
  } */
  .home .section-01 .container {
    left: 15%;
    right: 15%;
    /* padding-top: 140px; */
  }
  .home .section-01 {
    height: 40vw;
  }
  .home .section-01 .background-image {
    /*background-image: url("../../assets/imgs/9400@3x.jpg");*/
    background-repeat: no-repeat;
    background-attachment: local;
    background-position: center;
    background-size: cover;
    height: 40vw;
  }
}

@media (min-width: 1500px) {
  /* .background-image-3 {
    background-position: top !important;
  } */

  /* .home .section-01,
  .home .section-01 .background-image {
    height: 676px;
  } */

  .title-wrapper {
    padding-top: 23vw;
  }

  .title {
    font-size: 2.5vw;
  }
}

/*********************/

.home .section-02 {
  background-color: #fff4e5;
  padding: 36px 26px 36px;
}
.home .section-02 h2 {
  line-height: 1.7;
  color: var(--text-primary);
  font-size: 24px;
  margin-bottom: 24px;
}
.home > .section-02 > .container > div:first-child {
  margin-top: 24px;
  margin-bottom: 36px;
}
@media (min-width: 992px) {
  .home .section-02 {
    padding: 200px 0 90px;
  }
  .home .section-02 h2 {
    font-size: 40px;
    margin-bottom: 120px;
  }
}

/*********************/

.home .section-03 {
  background-color: white;
  padding: 83px 26px 91px;
}
.home .section-03 h2 {
  line-height: 1.7;
  color: var(--text-primary);
  font-size: 24px;
  margin-bottom: 91px;
  text-align: center;
}
@media (min-width: 992px) {
  .home .section-03 {
    padding: 200px 0 154px;
  }
  .home .section-03 h2 {
    font-size: 40px;
    margin-bottom: 200px;
  }
}
/*********************/

.home .section-04 {
  padding: 140px 26px;
  background-color: #fff4e5;
}

/*********************/

.home .section-05 {
  padding: 140px 26px;
  background-color: white;
}

@media (max-width: 570px) {
  .home .section-03, .home .section-04, .home .section-05 {
    padding: 64px 20px;
  }
}

/*********************/

.home .section-06 {
  padding: 84px 26px 40px;
  background-color: white;
}
.home .section-06 h2 {
  line-height: 1.7;
  color: var(--text-primary);
  font-size: 24px;
  margin-bottom: 80px;
  text-align: center;
}
.home .button-wrapper {
  margin-top: 35px;
  margin-bottom: 30px
}
@media (min-width: 992px) {
  .home .section-06 {
    padding: 200px 0px 170px;
  }
  .home .section-06 h2 {
    font-size: 40px;
    margin-bottom: 120px;
  }
  .home .button-wrapper {
    margin-top: 70px;
  }
  .home .button-wrapper button {
    font-size: 24px;
    font-weight: bold;
    padding-left: 36px;
    padding-right: 36px;
  }
}
/*********************/

.home .section-07 {
  /*padding: 84px 26px;*/
  background-color: white;
}
.home .title-holder {
  background-color: var(--line-color);
  padding: 46px 0;
}
.home .section-07 .sp {
  margin-bottom: 45px;
}
.home .section-07 h2 {
  line-height: 1.7;
  color: var(--text-primary);
  font-size: 24px;
  margin-bottom: 0;
  text-align: center;
}
@media (min-width: 992px) {
  .home .section-07 {
    /*padding: 200px 0px;*/
  }
  .home .title-holder {
    padding: 100px 0;
  }
  .home .section-07 h2 {
    font-size: 40px;
  }
  .home .section-07 .sp {
    margin-bottom: 140px;
  }
}

/*********************/

.home .section-08 {
  padding: 70px 26px;
  background-color: var(--bg-color-1);
}

.home .section-08 video {
  width: 100%;
  margin-bottom: 20px;
}

.home .section-08 .slick-dots li.slick-active button:before {
  color: var(--primary-color);
}

.home .section-08 h2 {
  line-height: 1.7;
  color: var(--text-primary);
  font-size: 24px;
  margin-bottom: 40px;
}

@media (min-width: 992px) {
  .home .section-08 {
    padding: 140px 0px 140px;
  }
  .home .section-08 h2 {
    font-size: 40px;
    margin-bottom: 46px;
  }
  .home .section-08 .video {
    margin-right: 20px;
    margin-bottom: 0px;
    max-width: 649px;
  }
  .home .section-08 .video-carousel-wrapper {
    margin-left: -80px;
    margin-right: -80px;
  }
}

@media (min-width: 570px) {
  .home .section-08 > .container > div {
    justify-content: center;
  }
  .home .section-08 h2 {
    text-align: center;
  }
}

/*********************/

.home .section-09 {
  padding: 62px 26px 94px;
  background-color: white;
}
.home .section-09 h2 {
  line-height: 1.7;
  color: var(--text-primary);
  font-size: 24px;
  margin-bottom: 48px;
}
.home .section-09 .banner-2 {
  border-radius: 40px;
  overflow: hidden;
}
.home .section-09 .carousel-inner .img-section img {
  object-fit: cover;
}
.home .section-09 .carousel-inner .content {
  padding-top: 23px;
  padding-left: 23px;
  padding-bottom: 120px;
}
.home .section-09 .indicators {
  margin-bottom: 23px;
}
.home .section-09 .controls {
  bottom: 28px;
  left: 23px;
}
.home .section-09 .carousel-inner .content .btn-right-icon {
  font-size: 16px;
  font-weight: 500;
  color: var(--primary-color);
  padding-left: 0px;
}
@media (min-width: 640px) {
  .home .section-09 .indicators {
    top: 55%;
    left: 23px;
  }
}
@media (min-width: 794px) {
  .home .section-09 {
    padding: 200px 0px;
  }
  .home .section-09 h2 {
    font-size: 40px;
    margin-bottom: 64px;
  }
  .home .section-09 .banner-2 {
    min-height: 499px;
  }
  .home .section-09 .indicators {
    margin-bottom: 0;
    top: 56px;
    left: 64px;
  }
  .home .section-09 .controls {
    bottom: 56px;
    left: 64px;
  }
  .home .section-09 .carousel-inner .content {
    padding-top: 92px;
    padding-left: 64px;
  }
  .home .section-09 .carousel-inner .img-section img {
    object-fit: cover;
    min-height: 499px;
  }
}

/*********************/

.home .section-10 {
  height: 533px;
  background-image: url("../../assets/imgs/9500@3x.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: local;
}
.home .section-10 h2 {
  line-height: 1.7;
  color: var(--text-primary);
  font-size: 24px;
  margin-bottom: 75px;
  text-align: center;
}
.home .section-10 button,
.home .section-10 a {
  min-width: 274px;
}
@media (min-width: 992px) {
  .home .section-10 h2 {
    font-size: 40px;
    margin-bottom: 98px;
  }
}

/*********************/

.home .section-11 {
  height: 320px;
  background-image: url("../../assets/imgs/10151@3x.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: local;
}
.home .section-11 h2 {
  line-height: 1.7;
  font-size: 24px;
  text-align: center;
  color: white;
}
@media (min-width: 992px) {
  .home .section-11 h2 {
    font-size: 40px;
  }
}
@media (max-width: 798px) {
  .home .section-11 {
    height: 170px;
  }
}

/*********************/

.home .section-12 {
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: local;
  background-position: right;
}
.home .section-12 .overlay-1 {
  background-repeat: no-repeat;
  background-size: contain;
  background-attachment: local;
  background-position: right top;
  border-top-right-radius: 40px;
  background-color: #ff9000;
  min-width: 100%;

  padding: 38px;
  min-height: 319px;
  background-image: url("../../assets/imgs/10236@3x.webp");
  align-items: center;
}
.home .section-12 .overlay-2 {
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: local;
  background-position: right top;

  width: 100%;
  padding: 38px;
  min-height: 319px;
  background-image: url("../../assets/imgs/10235@3x.webp");
  align-items: center;
}
.home .section-12 h2 {
  font-weight: 900;
  text-align: left;
  color: white;
  font-size: 24px;
  line-height: 1.83;
}
.home .section-12 button {
  padding: 16px 28px;
  border-radius: 4px;
  box-shadow: 0 5px 10px 0 #eb8400;
  border: solid 1px var(--line-color);
  background-color: white;
  font-size: 16px;
  font-weight: 500;
  color: var(--primary-color);
}
.home .section-12 h3 {
  margin: 0 0 40px;
  font-size: 24px;
  font-weight: bold;
  line-height: 1.83;
  text-align: left;
  color: var(--text-primary);
}
.home .section-12 a {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.63;
}
@media (min-width: 992px) {
  .home .section-12 h2 {
    font-size: 40px;
    margin-bottom: 40px;
  }
  .home .section-12 {
    min-height: 414px;
    background-image: url("../../assets/imgs/10149@3x.webp");
  }
  .home .section-12 .overlay-1 {
    padding: 0;
    min-height: 414px;
    position: absolute;
    right: 40vw;
    background-image: url("../../assets/imgs/10068@3x.webp");
    padding-right: 95px;
    align-items: flex-end;
  }
  .home .section-12 .overlay-2 {
    padding: 0;
    padding-left: 100px;
    width: 40vw;
    min-height: 414px;
    margin-right: 4rem;
    background-image: none;
    align-items: flex-start;
  }
  .home .section-12 h3 {
  }
  .home .section-12 button {
  }
  .home .section-12 a {
  }
  .home .section-12 .container {
    
  }
}

@media (max-width: 798px) {
  .home .section-12 .overlay-1 {
    background-color: #ff9000;
    padding-left: 30px;
    align-items: flex-start;
  }
  .home .section-12 .overlay-2 {
    min-height: 245px;
    padding-left: 30px;
    align-items: flex-start;
  }
  .home .section-12 .overlay-2 h3 {
    font-size: 18px;
  }
  .home .section-12 .overlay-2 a {
    font-size: 12px;
  }
  .home .section-12 .overlay-1 button img {
    width: 20px;
    margin-left: 20px;
    margin-bottom: 1px;
  }
  .home .section-12 .overlay-2 img {
    width: 20px;
    margin-left: 20px;
    margin-bottom: -1px;
  }
}