.recommend-map table {
  border-collapse: collapse;
  border-spacing: 0;
}

.recommend-map table th,
.recommend-map table td {
  border: 1px solid var(--text-secondary);
  padding: 5px 10px;
  text-align: center;
  vertical-align: middle;
  color: var(--text-primary);
}

.recommend-map table td:last-child {
  width: auto;
}