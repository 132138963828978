/* generic */
.auth-paper {
  padding: 72px 71px 98px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: white;
  /* width: 100vw; */
  height: fit-content;
  margin-top: 60px;
  margin-bottom: 60px;
}

@media (max-width: 798px) {
  .auth-paper {
    padding: 24px;
    margin-top: 0px;
    margin-bottom: 0px;
    overflow-x: hidden;
  }
}

.auth-paper .header p {
  color: var(--text-secondary);
}

.auth-note {
  background-color: var(--bg-color-1);
  border-radius: 4px;
  padding: 28px 32px;
  min-height: 80px;
  color: var(--text-primary);
}
.auth-note > * {
  margin: 0;
}
.textbox-action {
  display: flex;
}
.auth-paper .textbox-action .recommendation_id_input {
  max-width: calc(100% - 145px);
}
.textbox-action button {
  white-space: nowrap;
}
.auth-paper .textbox-action input {
  max-width: calc(100% - 120px);
}

/* signIn */
.auth-paper .recover-targets {
  margin-top: 16px;
}
.auth-paper .recover-targets a {
  margin-right: 58px;
  color: var(--text-secondary);
}

/* signUpMethod */

/* signUpType */
.auth-paper .choice {
  margin: 24px 0 21px;
  padding: 32px 34px 40px;
  border: solid 1px var(--line-color);
  background-color: white;
}
.auth-paper .choice h3 {
  margin: 0 0 8px;
}
.auth-paper .choice p {
  color: var(--text-secondary);
  margin-bottom: 24px;
}
.auth-paper .choice .line {
  border-top: 1px solid var(--disabled-line);
}
.auth-paper .choice > img {
  margin: 32px 82px;
}
.auth-paper .auth-note {
  margin-top: 21px;
}
.auth-paper .auth-note span {
  display: block;
}

/* signUpEmail */
.auth-paper .checkboxes {
  margin-bottom: 56px;
}
.auth-paper .checkbox button {
  color: var(--text-secondary);
}
.auth-paper .checkbox-group {
  margin-bottom: 16px;
}
.auth-paper .checkbox-group label {
  margin: 0 28px;
  display: inline-block;
}

/* signUpResult */
.auth-paper img.hero {
  margin-bottom: 24px;
}

.sign-up-result button.primary {
  border-radius: 12px;
  border-end-end-radius: 0px;
} 
.sign-up-result button.secondary {
  margin-top: 24px;
} 

@media (max-width: 798px) {
  .sign-up-result {
    flex-grow: 1;
    height: 100%;
  }
  .sign-up-result p {
    font-size: 12px;
    line-height: 1.83;
  }
  .sign-up-result button.primary {
    margin-top: 24px;
  } 
}

/* recoverId */
.auth-paper .recover-action {
  margin-top: 40px;
}
.auth-paper p.result-title {
  font-weight: bold;
}
.auth-paper .result-info {
  margin: 16px 0 16px;
  padding: 30px 16px;
  border-radius: 4px;
  border: solid 1px var(--line-color);
}
.auth-paper .result-info p {
  margin-bottom: 0;
}

.social-button + .social-button {
  margin-top: 16px;
}
