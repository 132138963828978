.community .categories-section {
  display: flex;
  margin: 56px 0 24px;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.community .categories-section::-webkit-scrollbar {
  display: none;
}

.community .categories-section button {
  white-space: nowrap;
}
.community .desc-section {
  padding: 32px 0 56px;
  border-bottom: solid 1px var(--line-color);
}

@media (max-width: 570px) {
  .community .categories-section {
    display: flex;
    width: 100%;
    margin-top: 35px;
    border-width: 0;
    border-bottom: solid 1px var(--line-color);
  }

  .community .categories-section button {
    box-sizing: border-box;
    padding: 10px 10px 15px 10px;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.2;
    text-align: center;
    border-radius: 0;
  }

  .community .categories-section button.active {
    color: var(--primary-color);
    font-weight: bold;
    border-bottom: solid 3px var(--primary-color);
    margin-bottom: -1px;
  }
  .community .desc-section {
    padding: 24px;
    padding-top: 0px;
    margin-bottom: 0px;
  }
}

.community .sort-commands {
  margin: 56px 0;
}

.community .sort-commands button {
  margin: 0;
  font-weight: 400;
  padding: 0;
  border: none;
}
.community .sort-commands label {
  margin: 0;
}

.community .sort-commands .divider-vertical {
  margin: 0 12px;
}

@media (max-width: 570px) {
  .community .sort-commands {
    margin: 24px;
  }
}

.community .skb-fab {
  display: none;
}
@media (max-width: 798px) {
  .community .skb-fab {
    display: block;
  }
}

/**********************************/

.post-paper .title {
  font-size: 16px;
  font-weight: bold;
  text-align: left;
  color: var(--text-primary);
}
.post-paper .date {
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  color: var(--text-tertiary);
}
.post-paper .thumbnail img {
  width: 100%;
}
.post-paper .content {
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  margin-bottom: 12px;
  color: var(--text-secondary);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.post-paper .reactions {
  margin: 8px 0;
}
.post-paper .reactions button {
  display: inline-block;
  padding: 0;
  margin: 0;
  margin-right: 16px;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.61;
  text-align: left;
  color: var(--text-primary);
}
.post-paper .comments img,
.post-paper .likes img {
  display: inline-block;
  width: 26px;
  height: 26px;
  margin-right: 8px;
}
.post-paper .author {
  font-size: 14px;
  font-weight: bold;
  line-height: 1.57;
  text-align: left;
  color: var(--text-secondary);
}
.post-paper button {
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: normal;
  text-align: left;
  color: var(--text-secondary);
}

.post-paper .title {
  margin-bottom: 12px;
}

.posts-section {
  gap: 58px 30px;
}

@media (max-width: 798px) {
  .posts-section {
    padding: 0 24px;
  }
}

/*********************************/

.breadcrumb-section a,
.breadcrumb-section li {
  font-size: 22px;
  font-weight: 500;
  color: var(--text-secondary);
}
.post-section {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: white;
  margin-bottom: 64px;
}
.post-section .post-header {
  padding: 24px 60px 0;
  background-color: white;
}
.post-section .post-header .details {
  margin: 16px 0;
}
.post-section .post-header .author-label,
.post-section .post-header .author,
.comment .author {
  font-size: 16px;
  font-weight: bold;
  text-align: left;
  color: var(--text-secondary);
  margin-right: 12px;
}
.post-section .post-header .date-label {
  font-size: 16px;
  margin: 0 20px;
  font-weight: bold;
  text-align: left;
  color: var(--text-secondary);
}
.post-section .post-header .date,
.comment .date {
  font-size: 16px;
  font-weight: 500;
  color: var(--text-tertiary);
}
@media (max-width: 798px) {
  .post-section {
    box-shadow: none;
    margin-bottom: 0px;
    border-bottom: solid 1px var(--line-color);
  }
  .post-section .post-header {
    padding: 16px 25px;
  }
  .post-section .post-header > h2 {
    font-size: 15px;
    margin-bottom: 9px;
    max-width: 200px;
  }
  .post-section .post-header span {
    font-size: 15px;
    font-weight: 500;
  }
  .post-section .post-header .author,
  .post-section .post-header .date {
    font-size: 12px;
    font-weight: 500;
    color: var(--text-secondary);
  }
  .post-section .post-container {
    padding: 16px 25px;
  }
  .post-section .divider-tertiary {
    margin: 0 0;
  }
  .subcmt-wrapper {
    padding: 0 25px;
  }
}

.post-section .post-content {
  margin: 24px 64px 64px 64px;
}

.post-section .post-footer {
  margin: 24px 64px;
  padding-bottom: 100px;
}
.mobile-button-wrapper {
  padding: 16px 25px;
  border-top: solid 1px var(--line-color);
}
@media (max-width: 798px) {
  .post-section .post-content {
    margin: 16px 0px;
  }
  .post-section .post-content * {
    font-size: 12px;
  }
  .community .button-wrapper {
    padding: 25px 25px 17px 25px;
  }
  .community .button-wrapper > button {
    min-width: unset;
    min-height: unset;
    width: 100%;
    padding: 16px 0;
    font-size: 12px;
  }
  .community .button-wrapper > button > img {
    width: 17px;
  }
  /* .community {
    flex-grow: unset !important;
  } */
}
.comments-section-header {
  margin: 16px 0;
  font-weight: bold;
}
.comments-section-header span:before {
  content: url("../../assets/icons/icon-filled-application-comment-26-px-text-1.svg");
  display: inline-block;
  padding-right: 8px;
  vertical-align: middle;
}
.comments-section,
.comments-section-empty {
  margin: 20px 36px;
}
.comments-section-empty {
  font-size: 16px;
  font-weight: bold;

  color: var(--text-tertiary);
}
.btn-delete {
  color: #e01d27;
  margin-left: 10px;
}
@media (min-width: 798px) {
  .comments-section-header {
    margin: 40px 0 0;
  }
  .btn-delete {
    border: solid 1px #e01d27 !important;
    max-height: 58px;
    margin-left: 16px;
  }
}
/*********************************/
.comment-wrapper {
  padding: 16px 0;
}
.comment-wrapper + .comment-wrapper {
  border-top: solid 1px var(--line-color);
}
.comment .author {
  margin-bottom: 16px;
}
.comment .author.active {
  color: var(--primary-color);
}
.comment .content {
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  color: var(--text-primary);
  margin-bottom: 16px;
}
.comment .add-comment {
  padding-left: 0;
  padding-right: 0;
  color: var(--text-tertiary);
  font-size: 18px;
  font-weight: bold;
}
.comment-wrapper .sub-comments-section {
  margin-top: 8px;
  border-top: solid 1px var(--line-color);
}

.comment .comment-edit {
  cursor: pointer;
}

.comment .btn-delete {
  border: none !important;
}
.sub-comment {
  padding: 10px 24px 5px;
}
.comment:hover {
  transition: 0.3s;
  background-color: #f9f9f9;
}

.post-section .comment-writter,
.comment-page .comment-writter,
.comment .comment-writter {
  margin-bottom: 8px;
}

.post-section .comment-writter input,
.comment-page .comment-writter input,
.comment .comment-writter input {
  padding: 4px 8px;
}

@media (max-width: 798px) {
  .comment .author {
    margin-bottom: 0px;
  }
  .sub-comment {
    padding: 16px 24px 0 48px !important;
  }
  .post-section .comment-writter,
  .comment-page .comment-writter,
  .comment .comment-writter {
    flex-direction: column;
    align-items: flex-start;
  }
}

/*********************************/

.create-post-paper {
  margin: 60px 0 100px;
  padding: 0 0 100px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: white;
}

.create-post-paper .attach-text {
  font-size: 18px;
  font-weight: bold;
  line-height: 1.61;
  text-align: left;
}
.create-post-paper .validations-section {
  padding: 0px 60px;
}
.create-post-paper .attach-text span {
  color: var(--error-color);
}
.create-post-paper .category-section {
  border-bottom: solid 1px var(--line-color);
}
.create-post-paper .category-section .accordion-header button {
  padding: 24px 60px;
  margin-bottom: 0;
  font-weight: bold;
  font-size: 18px;
}
.create-post-paper .title-section {
  padding: 24px 60px;
}
.create-post-paper .title-section input {
  border: none;
}
.editor-section {
  padding: 12px 0 24px 0;
  min-height: 600px;
}
.editor-section .ck-editor__editable {
  min-height: 600px;
}
.create-post-paper .asset-section {
  padding: 24px 60px;
  margin-bottom: 56px;
  border-bottom: solid 1px var(--line-color);
  border-top: solid 1px var(--line-color);
}
.create-post-paper .asset-section button {
  margin-bottom: 0;
  margin-right: 16px;
}
.create-post-paper .asset-section small {
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  color: var(--text-tertiary);
}
.create-post-paper .action-section {
  padding: 0 60px;
}
.create-post-paper .action-section button {
  padding-left: 60px;
  padding-right: 60px;
}

.create-post-paper .accordion-item .accordion-body {
  padding: 0;
}

.create-post-paper .category-selector {
  color: var(--button-secondary-color);
  margin: 0;
  text-align: left;
  border-bottom: 1px solid var(--line-color);
  padding-left: 64px;
}
.create-post-paper .category-selector:last-child {
  border-bottom: none;
}
.create-post-paper .category-selector:hover {
  color: var(--text-tertiary);
}
.create-post-paper .category-selector:active {
  color: var(--text-primary);
}

@media (max-width: 798px) {
  .create-post-paper {
    margin-top: 3px;
    margin-bottom: 0px;
    padding: 0 0;
    box-shadow: none;
  }
  .create-post-paper .category-section .accordion-header button,
  .editor-section {
    padding: 24px;
  }
  .create-post-paper .title-section {
    padding: 8px 14px;
    border-bottom: 1px solid var(--line-color);
  }
  .mobile-button-wrapper .reaction-button {
    padding: 0;
  }
  .mobile-button-wrapper .reaction-button span {
    font-size: 14px;
    color: var(--text-primary);
  }
  .editor-section {
    min-height: 250px;
  }
  .editor-section .ck-editor__editable {
    min-height: 250px;
  }
  .create-post-paper .asset-section {
    padding: 16px 24px;
    margin-bottom: 16px;
  }
  .create-post-paper .asset-section small {
    font-size: 9px;
    word-break: break-all;
  }
}

.asset-selector .actions-section {
  margin: 16px 64px;
}
.asset-selector .actions-section button {
  margin-left: 16px;
  padding-left: 30px;
  padding-right: 30px;
  margin-bottom: 0;
}
.asset-selector .assets-holder {
  background-color: var(--bg-color-1);
  padding: 64px 46px;
  min-height: 400px;
  max-height: 600px;
  overflow-y: scroll;
}
.asset-selector .assets-holder button {
  margin-bottom: 0;
  padding: 0;
  border: 2px solid transparent;
  text-align: left;
}
.asset-selector .assets-holder button img {
  margin: 0;
  width: 260px;
}
.asset-selector .assets-holder button .tem-name {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.64;
  letter-spacing: normal;
  text-align: left;
  color: var(--text-secondary);
}

.asset-selector .assets-header {
  font-size: 18px;
  font-weight: bold;
  text-align: left;
  color: var(--text-primary);
  padding: 16px;
}
.asset-selector .asset-action.active {
  border: 2px solid var(--text-primary);
}

.comment-page {
  padding: 24px 26px 24px;
  background-color: white;
}

.comment-page .textbox-action {
  margin-bottom: 24px;
}

.comment-page .comments-section {
  margin: 0 -26px;
}

.comment-page .comment {
  padding: 16px 25px;
}

.comment-page input {
  max-width: calc(100% - 90px);
}

.post-section .commenter-id,
.comment-page .commenter-id {
  margin-left: 5px;
  margin-bottom: 8px;
  font-weight: 700;
  color: var(--text-secondary);
}

.post-section textarea,
.comment-page textarea {
  padding: 16px 14px;
  border-radius: 4px;
  border: solid 1px var(--disabled-line);
  background-color: white;
  font-weight: 500;
  color: var(--text-secondary);
  min-height: unset;
}

.hover-video-view {
  visibility: hidden;
  opacity: 0;
}

.post-paper:hover .hover-video-view {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.2s;
}

.best-gallery .carousel-inner {
  height: 100%;
}

.best-gallery .indicators .dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin: 0 10px;
  background-color: var(--bg-color-2);
  border-radius: 4px;
}

.best-gallery .indicators .dot.active {
  width: 32px;
  height: 8px;
  margin: 0 10px 0 0;
  border-radius: 4px;
  background-color: #eb8400;
}

.best-gallery {
  background-image: url("../../assets/imgs/background@3x.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: local;
}

.video-loading {
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 3px solid rgba(255,144,0,.3);
  border-radius: 50%;
  border-top-color: var(--primary-color);
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to { -webkit-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
  to { -webkit-transform: rotate(360deg); }
}

.community button[disabled],
.community button[disabled]:hover {
  background-color: unset;
  border-width: 0;
}

.event-list {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.event-list::-webkit-scrollbar {
  display: none;
}