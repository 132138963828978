@media (max-width: 1134px) {
  .our-services .container {
    padding: 0 100px !important;
  }
}

@media (max-width: 576px) {
  .our-services .container {
    padding: 0 25px !important;
  }
}

/*********************/

.our-services .gray-steps-paper {
  background-color: var(--bg-color-1);
  padding: 32px 12px;
  border-radius: 40px;
  margin-bottom: 64px;
}
.our-services .gray-steps-paper span {
  font-size: 12px;
  font-weight: bold;
  line-height: 1.58;
  color: var(--text-primary);
  text-align: center;
}
.our-services .gray-steps-paper .dots img {
  width: 10vw;
  padding-bottom: 1.5rem;
}

@media (min-width: 798px) {
  .our-services .gray-steps-paper {
    padding: 80px 24px;
    margin-bottom: 140px;
  }
  .our-services .gray-steps-paper span {
    font-size: 24px;
  }
  .our-services .gray-steps-paper .dots img {
    width: auto;
    padding-bottom: 1.58rem;
  }
}

@media (max-width: 798px) {
  .our-services .container {
    padding-left: 0px;
    padding-right: 0px;
  }
}

/*********************/
.our-services .intro-paper .gray-part {
  padding: 40px;
  background-color: var(--bg-color-1);
}
.our-services .intro-paper .white-part {
  background-color: white;
  padding: 40px;
  border-bottom: solid 1px var(--line-color);
}
.our-services .intro-paper span.note {
  font-size: 12px;
  font-weight: bold;
  line-height: 3.42;
  text-align: left;
  color: var(--primary-color);
}
.our-services .intro-paper h3,
.our-services .intro-paper p {
  font-size: 24px;
  font-weight: bold;
  line-height: 1.83;
  text-align: left;
  color: var(--text-primary);
}
/*.our-services .intro-paper p {
  color: var(--text-secondary);
} */
.our-services .intro-paper img {
  float: right;
  clear: both;
}
@media (max-width: 798px) {
  .our-services .intro-paper .gray-part {
    padding: 16px 12px;
  }
  .our-services .intro-paper .white-part {
    padding: 16px 12px;
  }
  .our-services .intro-paper h3,
  .our-services .intro-paper p {
    font-size: 18px;
  }
  .our-services .intro-paper img {
    float: none;
    width: 103px;
  }
}

/*********************/

.our-services .note > span {
  font-size: 16px;
  font-weight: bold;
  line-height: 2.75;
  text-align: left;
  color: var(--primary-color);
}
.our-services .note h3 {
  font-size: 24px;
  font-weight: bold;
  line-height: 1.83;
  text-align: left;
  color: var(--text-primary);
}

/*********************/

.our-services .section-01 {
  background-image: url("../../assets/imgs/7886@3x.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: local;
  height: 1000px;
  padding-top: 160px;
}
.our-services .section-01 h1 {
  font-size: 40px;
  font-weight: 900;
  line-height: 1.75;
  text-align: left;
  color: white;
  margin-bottom: 33px;
}
.our-services .section-01 p {
  margin-bottom: 33px;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.86;
  text-align: left;
  color: white;
  margin-bottom: 53px;
}
.our-services .section-01 .video-holder {
  width: 100%;
  margin-bottom: -50px;
}
@media (max-width: 798px) {
  .our-services .section-01 {
    min-height: 476px;
    height: 55vw;
    padding: 56px 0 0 0;
  }
  .our-services .section-01 h1 {
    font-size: 24px;
    line-height: 39px;
  }
  .our-services .section-01 p {
    font-size: 14px;
  }
}
@media (max-width: 576px) {
  /* .our-services .section-01 {
    min-height: 520px;
    height: 110vw;
    padding: 56px 25px 0 25px;
  }
  .our-services .section-01 h1 {
    font-size: 24px;
    line-height: 39px;
  }
  .our-services .section-01 p {
    font-size: 14px;
  } */
}

/*********************/

.our-services .section-02 {
  padding: 40vw 0 0px;
  background-color: white;
}
.our-services .section-02 h2 {
  font-size: 24px;
  font-weight: 900;
  line-height: 1.83;
  text-align: center;
  color: var(--text-primary);
}
.our-services .section-02 .note {
  margin-bottom: 55px;
}
.our-services .section-02 p.subtitle {
  font-size: 18px;
  font-weight: bold;
  line-height: 1.67;
  text-align: center;
  color: var(--text-secondary);
  margin-bottom: 75px;
}
.our-services .section-02 .tab-list-primary {
  font-weight: bold;
  font-size: 12px;
  white-space: nowrap;
}
.our-services .section-02 .tab-primary {
  margin: 0;
}
.our-services .section-02 .video-tags video {
  margin-bottom: 26px;
}
.our-services .section-02 .video-tags .content {
  display: block;
  background-color: #fff4e5;
  margin: 0;
  padding: 24px 40px;
  margin-bottom: 60px;
  color: var(--text-secondary);
  text-align: left;
  border-radius: 30px;
  border-bottom-right-radius: 0;
}
.our-services .section-02 .step-papaer {
  max-width: 800px;
}
@media (min-width: 576px) {
  .our-services .section-02 {
    padding: 28vw 0 50px;
  }
}
@media (min-width: 798px) {
  .our-services .section-02 {
    padding: 330px 26px 80px;
  }
  .our-services .section-02 .note {
    margin-bottom: 100px;
  }
  .our-services .section-02 h2 {
    font-size: 40px;
  }
}

/*********************/

.our-services .section-03 {
  background-color: var(--bg-color-1);
  padding: 80px 0;
}
.our-services .section-03 h3 {
  margin-bottom: 24px;
  font-size: 18px;
}
.our-services .section-03 .part-1 {
  margin-bottom: 60px;
}
.our-services .section-03 .image-wrapper {
  margin-top: 53px;
}
@media (min-width: 576px) {
  .our-services .section-03 .part-1 {
    margin-bottom: 126px;
  }
}
@media (min-width: 798px) {
  .our-services .section-03 {
    padding: 140px 0;
  }
  .our-services .section-03 .part-1 {
    margin-bottom: 140px;
  }
  .our-services .section-03 .image-wrapper {
    margin-top: 0px;
  }
  .our-services .section-03 h3 {
    font-size: 24px;
    margin-bottom: 40px;
  }
}

/*********************/

.our-services .section-04 {
  background-color: white;
  padding: 80px 0;
}
.our-services .section-04 .part-1 {
  margin-bottom: 60px;
}
.our-services .section-04 .note button {
  margin-bottom: 40px;
}
.our-services .section-04 .info-paper {
  padding: 64px 28px;
}
.our-services .section-04 .info-paper img {
  width: 72px;
  height: 72px;
}
@media (max-width: 798px) {
  .our-services .section-04 .info-paper {
    margin: 64px 0 0;
  }
}
@media (min-width: 576px) {
  .our-services .section-04 .info-paper {
    padding: 64px 49px;
  }
}
@media (min-width: 798px) {
  .our-services .section-04 {
    padding: 140px 0 200px;
  }
  .our-services .section-04 .part-1 {
    margin-bottom: 200px;
  }
  .our-services .section-04 .info-paper {
    margin-bottom: 0px;
    padding: 53px 59px 53px;
  }
  .our-services .section-04 .info-paper img {
    width: auto;
    height: auto;
  }
}

/*********************/

.our-services .section-05 {
  padding: 64px 0;
  background-color: #fff4e5;
}
.our-services .section-05 h2 {
  font-size: 24px;
  font-weight: 900;
  line-height: 1.83;
  text-align: center;
  color: var(--text-primary);
}
.our-services .section-05 p {
  font-size: 18px;
  font-weight: bold;
  line-height: 1.67;
  text-align: center;
  color: var(--text-secondary);
  margin-bottom: 75px;
}
.our-services .section-05 img {
  width: 100%;
}

@media (min-width: 798px) {
  .our-services .section-05 {
    padding: 200px 0;
  }
  .our-services .section-05 h2 {
    font-size: 40px;
  }
  .our-services .section-05 p {
    font-size: 24px;
  }
}

/*********************/

.our-services .section-06 {
  padding: 80px 0px 80px;
  background-color: white;
}
.our-services .section-06 h2 {
  font-size: 24px;
  font-weight: 900;
  line-height: 1.83;
  text-align: center;
  color: var(--text-primary);
}
.our-services .section-06 > div > p.subtitle {
  font-size: 18px;
  font-weight: bold;
  line-height: 1.67;
  text-align: center;
  color: var(--text-secondary);
  margin-bottom: 75px;
}

@media (min-width: 798px) {
  .our-services .section-06 {
    padding: 200px 0;
  }
  .our-services .section-06 h2 {
    font-size: 40px;
  }
  .our-services .section-06 > div > p.subtitle {
    font-size: 24px;
  }
}

/*********************/

.our-services .section-07 {
  padding: 69px 0 0 0;
  background-image: url("../../assets/imgs/10294@3x.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: local;
  overflow-y: hidden;
}
.our-services .section-07 h2 {
  font-size: 24px;
  font-weight: 900;
  line-height: 1.63;
  color: white;
}
.our-services .section-07 p {
  font-size: 16px;
  font-weight: bold;
  line-height: 38px;
  color: white;
}
.our-services .section-07 a {
  border-radius: 4px;
  width: fit-content;
  padding: 16px 28px;
  box-shadow: 0 5px 10px 0 #eb8400;
  border: solid 1px var(--line-color);
}
.our-services .section-07 .section-image {
  position: none;
  align-self: center;
  width: 70vw;
  margin-top: 35px;
  margin-bottom: -10vw;
}
@media (min-width: 576px) {
  .our-services .section-07 {
    padding: 57px 0;
  }
  .our-services .section-07 h2 {
    font-size: 24px;
  }
  .our-services .section-07 p {
    font-size: 18px;
    margin-bottom: 28px;
  }

  .our-services .section-07 .section-image {
    width: 35vw;
    margin-bottom: -100px;
    margin-left: 10px;
  }
}
@media (min-width: 798px) {
  .our-services .section-07 {
    padding: 100px 26px 100px 10px;
  }
  .our-services .section-07 h2 {
    font-size: 40px;
  }
  .our-services .section-07 p {
    font-size: 24px;
    margin-bottom: 40px;
  }

  .our-services .section-07 .section-image {
    position: absolute;
    right: 0;
    width: auto;
    bottom: -5vw;
  }
}

@media (min-width: 1134px) {
  .our-services .section-07 .section-image {
    bottom: -170px;
    margin: 0;
  }
}

.cabletv .scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

.cabletv .scrollbar-hidden {
  -ms-overflow-style: none;  /* IE and 엣지 */
  scrollbar-width: none;  /* 파이어폭스 */
}