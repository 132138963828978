.mobile-header {
  padding: 0 25px;
}

.report {
  max-width: 100vw;
  overflow-x: hidden;
}

.report .side-menu {
  display: none;
}

.report .content {
  width: 100%;
}

.report .content .date-wrapper {
  background-color: white;
  padding: 16px;
  margin-top: 3px;
  border-bottom: solid 8px var(--line-color);
}

.report .container {
  max-width: 100%;
  padding: 0;
}

@media (min-width: 992px) {
  .report .content {
    margin-left: 65px;
  }
  .report .content {
    margin-top: 72px;
  }
  .report .side-menu {
    display: block;
    width: 200px;
    padding: 32px 16px;
  }

  .report .container {
    max-width: 1134px;
  }

  .mobile-header {
    display: none;
  }

  .report .content .date-wrapper {
    border-bottom: solid 3px var(--line-color);
  }
}

@media (min-width: 1134px) {
  .report .content {
    max-width: 900px;
  }
}

.report .content .date-wrapper > div {
  flex: 1;
}

.report .content .tab-buttons {
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.report .content .tab-buttons::-webkit-scrollbar {
  display: none;
}
.report .content .tab-buttons button {
  box-sizing: border-box;
  white-space: nowrap;
  padding: 27px 16px 15px 16px;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.2;
  text-align: center;
  border-radius: 0;
  border-bottom: solid 1px var(--line-color);
}
.report .content .tab-buttons button.active {
  color: var(--primary-color);
  font-weight: bold;
  border-bottom: solid 1px var(--primary-color);
}

@media (min-width: 798px) {
  .report .content .tab-buttons {
    margin-top: 20px;
  }
  .report .content .tab-buttons button {
    font-size: 16px;
    font-weight: bold;
  }
}

.report .content .statistic-item {
  padding: 16px 25px;
}

.report .content .statistic-item h3 {
  font-size: 14px;
  font-weight: bold;
  letter-spacing: normal;
}

.report .content .graph-wrapper {
  height: 320px;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.report .content .graph-wrapper::-webkit-scrollbar {
  display: none;
}

@media (min-width: 796px) {
  .report .content .graph-wrapper {
    height: 570px;
  }

  .report .content .statistic-item h3 {
    font-size: 18px;
  }
}

@media (min-width: 992px) {
  .report .content .statistic-item {
    border: solid 1px var(--line-color);
    margin-top: 44px;
  }

  .report .content .statistic-item h3 {
    font-size: 18px;
  }
}

.report .content .desc {
  background-color: #fff4e5;
  border-radius: 4px;
  padding: 16px;
  font-size: 15px;
  font-weight: bold;
  flex: 1;
}

.report .content .desc + .desc {
  margin-top: 8px;
}

@media (min-width: 798px) {
  .report .content .desc + .desc {
    margin-top: 0;
    margin-left: 16px;
  }
}

.report .loading {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.report .dimmed {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #222222;
  opacity: 0.3;
  z-index: 1;
}

.report .loading img {
  z-index: 1;
}

.report-detail .content h3 {
  margin: 0;
  padding: 16px 25px;
}

.report-detail .content .content-inner {
  border: solid 1px var(--line-color);
}

@media (min-width: 992px) {
  .report-detail .content .content-inner {
    margin-top: 31px;
    padding: 16px 24px;
  }
  .report-detail .content h3 {
    padding: 0;
  }
}

.report-detail .content .info-section {
  border-top: solid 8px var(--line-color);
  padding: 16px 25px;
}

.report-detail .content .info-section .subtitle {
  color: var(--text-secondary);
  font-size: 16px;
  font-weight: bold;
  line-height: 1.63;
  margin-bottom: 24px;
}

.report-detail .content .info-section .subtitle-bold {
  color: var(--text-primary);
  font-size: 16px;
  font-weight: bold;
  line-height: 1.63;
  margin-bottom: 8px;
}

@media (min-width: 992px) {
  .report-detail .content .info-section {
    margin-top: 40px;
    border-width: 0;
    padding: 0;
  }
}

.report-detail .video-wrapper button {
  position: absolute;
  top: 10px;
  right: 25px;
  font-size: 12px;
  padding: 10px 12px;
  border: solid 1px var(--line-color);
  color: var(--text-secondary);
}

.report-detail .video-wrapper button img {
  width: 17px;
}

.report-detail .content video {
  width: 100%;
  height: auto;
}

.report-detail .region-tags {
  border: solid 1px var(--line-color);
  background-color: white;
  padding: 16px;
  overflow-x: scroll;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.report-detail .region-tags::-webkit-scrollbar {
  display: none;
}

.report-detail .region-tags > div:first-child {
  min-width: 100vw;
}

.report-detail .region-tags .region-item {
  font-size: 12px;
  padding: 8px;
  margin-right: 8px;
  border-radius: 2px;
  border: solid 1px var(--disabled-line);
  background-color: var(--disabled-bg);
  display: flex;
  flex-direction: row;
}

@media (min-width: 992px) {
  .report-detail .content video {
    width: 474px;
    height: 241px;
  }

  .report-detail .video-wrapper button {
    position: initial;
    margin-left: 27px;
    font-size: 18px;
    padding: 16px 24px;
  }

  .report-detail .video-wrapper button img {
    width: auto;
  }

  .report-detail .region-tags .region-item {
    font-size: 18px;
    height: auto;
    padding: 8px 16px;
    border-radius: 2px;
  }
}