.timeline {
}

.timeline li {
  position: relative;
  margin: 0 0 0 24px;
}

.timeline li .content {
  margin: 0;
  padding: 40px 24px 24px;
  margin-bottom: 60px;
  text-align: left;
  border-radius: 30px;
  border-bottom-right-radius: 0;
}

.timeline li .content:hover {
  background-color: #fff4e5;
}
.timeline li .content:hover h4:before {
  background-color: var(--primary-color);
}

.timeline li.selected .content {
  background-color: #fff4e5;
}
.timeline li.selected .content h4:before {
  background-color: var(--primary-color);
}

.timeline li h4 {
  font-size: 18px;
  font-weight: bold;
  color: var(--text-primary);
  margin-bottom: 24px;
}

.timeline li p {
  font-size: 18px;
  font-weight: 500;
  color: var(--text-secondary);
}

.timeline li span.line:before {
  position: absolute;
  left: -24px;
  content: "";
  border-left: solid 4px var(--line-color);
  height: 100%;
}

.timeline li:last-child span.line:before {
  border: none;
}

.timeline li h4:before {
  transition: 0.3s;
  left: -34px;
  position: absolute;
  content: "";
  display: inline-block;
  width: 24px;
  height: 24px;
  background-color: var(--line-color);
  border-radius: 50%;
}
