.ad-campaign-card,
.asset-card {
  padding: 18px;
  border: solid 1px var(--line-color);
  min-height: 403px;
}
.ad-campaign-card .status,
.asset-card .status {
  margin-bottom: 16px;
}
.ad-campaign-card .status-title,
.asset-card .status-title {
  display: block;
  margin: 0 0 0 4px;
  font-size: 18px;
  font-weight: bold;
  text-align: left;
  color: var(--text-primary);
}
.ad-campaign-card .media,
.asset-card .media {
  min-height: 179px;
  background-color: #e6e6e6;
  margin-bottom: 16px;
}
.ad-campaign-card .media-title,
.asset-card .media-title {
  margin-bottom: 4px;
}
.ad-campaign-card .media-title .title,
.asset-card .media-title .title {
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  color: var(--text-primary);
}
.ad-campaign-card .dates,
.asset-card .dates {
  margin-bottom: 16px;
  font-size: 12px;
  font-weight: 500;
  color: var(--text-tertiary);
}
.ad-campaign-card .dates div:first-child,
.asset-card .dates div:first-child {
  width: 80px;
  margin-right: 8px;
}
.ad-campaign-card .actions button,
.asset-card .actions button {
  margin-bottom: 0;
}
.ad-campaign-card .actions span,
.asset-card .actions span {
  display: block;
  padding: 16px;
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  color: var(--text-secondary);
  background-color: var(--bg-color-1);
}
.ad-campaign-card .note,
.asset-card .note {
  margin: 16px 0 0;
  padding: 14px 16px;
  background-color: var(--bg-color-1);
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  color: var(--text-secondary);
}

.ad-campaign-card .info,
.asset-card .info {
  padding-top: 26px;
  margin-bottom: 16px;
}
.ad-campaign-card .info p,
.asset-card .info p {
  font-size: 16px;
  font-weight: 500;
  color: var(--text-secondary);
}
.ad-campaign-card .info a,
.asset-card .info a {
  font-size: 14px;
}

.ad-campaign-card .actions button,
.ad-campaign-card .actions a {
  padding: 16px 9px;
}

.ad-campaign-list .applied {
  border: solid 3px var(--success-color);
}