.notices .notice-item {
  padding: 28px 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.63;
  border-bottom: solid 1px var(--line-color);
  transition: 0.3s;
}
.notices .notice-item:hover {
  color: var(--primary-color);
}
.notices .notice-item .index-col {
  min-width: 80px;
  text-align: center;
}
.notices .notice-item .tag-col {
  min-width: 140px;
}
.notices .notice-item .tag-col .tag {
  margin: 0;
}
.notices .notice-item .date-col {
  color: var(--text-tertiary);
  min-width: 75px;
  margin-left: 16px;
}
.notices .notice-item .icon-col {
  text-align: center;
  min-width: 124px;
}
.notices .notice-item .index-col,
.notices .notice-item .tag-col {
  text-align: center;
  padding: 0 16px;
}

@media (max-width: 794px) {
  .notices .notice-item {
    padding: 14px 0;
    font-size: 14px;
  }
  .notices .notice-item .icon-col {
    text-align: center;
    min-width: 75px;
  }
}

@media (max-width: 570px) {
  .notices .notice-item {
    padding: 14px 0;
    font-size: 14px;
  }
  .notices .notice-item .icon-col {
    text-align: center;
    min-width: 75px;
  }
  .notices .notice-item .tag-col {
    font-size: 11px;
    margin-bottom: 18px;
    padding: 0 25px 0 16px;
    min-width: 71px;
  }
  .notices .notice-item .title-col {
    padding: 0 25px 0 16px;
  }
  .notices .notice-item .date-col {
    min-width: 60px;
    margin-right: 16px;
  }
}

.notice-page {
  margin: 56px 0;
}
.notice-page .header {
  padding: 24px 16px 24px 17px;
  background-color: var(--bg-color-2);
}
.notice-page .header .breadcrumb-holder {
  margin-bottom: 16px;
  color: var(--text-tertiary);
  font-weight: 500;
}
.notice-page .header .breadcrumb-holder .tag-text {
  color: var(--text-secondary);
}
.notice-page .header .date {
  color: var(--text-tertiary);
  font-weight: 500;
}
.notice-page .content {
  padding: 16px 16px 16px;
}
.notice-page .content > div {
}
.notice-page .direction {
  margin-top: 58px;
}

.faq .search p.info {
  margin-bottom: 42px;
  font-size: 24px;
  font-weight: bold;
  color: var(--text-primary);
}

form.search-bar {
  margin-bottom: 72px;
  position: relative;
}
form.search-bar input {
  padding: 24px 42px 24px 14px;
}
form.search-bar input:focus ~ span:after {
  content: url("../../assets/icons/icon-outlined-application-search-large-text-2.svg");
}
form.search-bar span:after {
  content: url("../../assets/icons/icon-outlined-application-search-large-text-3.svg");
  position: absolute;
  top: 24px;
  right: 14px;
}

.faq-content .search {
  padding: 25px;
}

.faq-content .empty {
  padding: 180px 0;
  border: solid 1px var(--line-color);
  background-color: white;
}
.faq-content .empty .fir {
  font-size: 28px;
  font-weight: 900;
  line-height: 1.57;
  text-align: left;
  color: var(--text-primary);
  text-align: center;
}

@media (min-width: 1134px) {
  .faq-content {
    align-self: center;
    width: 1134px;
  } 
}

.estimate .label {
  font-size: 20px;
  color: var(--text-primary);
  font-weight: bold;
  line-height: 1.65;
  margin-top: 32px;
  margin-bottom: 11px;
}

.estimate .value {
  font-size: 20px;
  color: var(--text-primary);
  line-height: 1.65;
}

@media (max-width: 570px) {
  .estimate .label,
  .estimate .value {
    font-size: 14px;
  } 
}