.toggle-menu {
  /* position: relative; */
}

.toggle-menu button.action {
  margin: 0;
  padding: 0;
  padding-left: 16px;
  border: none;
}
.toggle-menu button.action img {
  vertical-align: baseline;
  margin: 0;
}
.toggle-menu .menu-holder.show {
  display: block;
}
.toggle-menu .menu-holder {
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 10px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
  background-color: white;
  color: #212529;
  text-align: left;
}
.toggle-menu .menu-holder button.item {
  padding: 10px 16px;
  margin: 0;
  border: none;
}
.toggle-menu .menu-holder button.item[disabled] {
  background-color: white;
}

@media (max-width: 798px) {
  .toggle-menu .menu-holder {
    right: 0px;
  }
}