@font-face {
  font-family: "Noto Sans KR";
  src: url("../fonts/NotoSansKR-Regular.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Noto Sans KR";
  src: url("../fonts/NotoSansKR-Light.otf") format("opentype");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "Noto Sans KR";
  src: url("../fonts/NotoSansKR-Medium.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Noto Sans KR";
  src: url("../fonts/NotoSansKR-Thin.otf") format("opentype");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "Noto Sans KR";
  src: url("../fonts/NotoSansKR-Bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Noto Sans KR";
  src: url("../fonts/NotoSansKR-Black.otf") format("opentype");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "WantedSans";
  src: url("../fonts/WantedSans-Medium.otf") format("opentype");
  font-weight: 400;
}
@font-face {
  font-family: "WantedSans";
  src: url("../fonts/WantedSans-SemiBold.otf") format("opentype");
  font-weight: 500;
}
@font-face {
  font-family: "WantedSans";
  src: url("../fonts/WantedSans-ExtraBold.otf") format("opentype");
  font-weight: 700;
}
@font-face {
  font-family: "TSansBeta";
  src: url("../fonts/TSansBetaRegular.otf") format("opentype");
  font-weight: 400;
}