label.checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 0.7rem;
  font-weight: 500;
  line-height: 24px;
  color: var(--text-secondary);
}

label.radio {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: var(--text-secondary);
}

label.checkbox.primary,
label.radio.primary {
  font-size: 0.8rem;
  font-weight: bold;
}

label.checkbox input,
label.radio input {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
}

label.checkbox .checkmark {
  transition: 0.3s;
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
}
label.radio .checkmark {
  transition: 0.3s;
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 50%;
}

label.checkbox :after,
label.radio :after {
  content: "";
  position: absolute;
  display: none;
}

label.checkbox .checkmark:after {
  transition: 0.3s;
  left: 8px;
  top: 2px;
  width: 7px;
  height: 14px;
  border: 2px solid #ccc;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  display: block;
}
label.radio .checkmark:after {
  top: 7px;
  left: 7px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}
label.radio input:checked ~ .checkmark:after {
  display: block;
}

label.checkbox:hover input ~ .checkmark,
label.radio:hover input ~ .checkmark {
  border-color: var(--primary-color);
}
label.checkbox:hover .checkmark:after,
label.radio:hover .checkmark:after {
  border-color: var(--primary-color);
}
label.checkbox.brand:hover input ~ .checkmark,
label.radio.brand:hover input ~ .checkmark {
  border-color: var(--brand-color);
}

label.checkbox:hover input:checked ~ .checkmark:after {
  border-color: white;
}

label.checkbox input:checked ~ .checkmark,
label.radio input:checked ~ .checkmark {
  border: 1px solid var(--primary-color);
  background-color: var(--primary-color);
}

label.checkbox.brand input:checked ~ .checkmark,
label.radio.brand input:checked ~ .checkmark {
  border: 1px solid var(--brand-color);
  background-color: var(--brand-color);
}
label.checkbox input:checked ~ .checkmark:after,
label.radio input:checked ~ .checkmark:after {
  border-color: white;
}
label.checkbox input:checked ~ span,
label.radio input:checked ~ span {
  color: var(--text-primary);
}

label.radio input:checked ~ .checkmark:after {
  display: block;
}

label.checkbox.disabled,
label.radio.disabled {
  cursor: unset;
}
label.checkbox.disabled .checkmark,
label.radio.disabled .checkmark {
  background-color: var(--disabled-bg);
}
label.checkbox.disabled input[disabled] ~ .checkmark,
label.radio.disabled input[disabled] ~ .checkmark {
  border-color: var(--disabled-line);
}
label.checkbox.disabled input[disabled] ~ .checkmark:after,
label.radio.disabled input[disabled] ~ .checkmark:after {
  border-color: var(--disabled-line);
}
