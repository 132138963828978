.datepicker-container {
  font-family: "Noto Sans KR", apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji",
    sans-serif;
  font-size: 16px;
  margin: 0 0 1px;
  border-radius: 2px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.16);
  background-color: white;
  border: none;
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

@media (min-width: 570px) {
  .datepicker-container {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

.datepicker-content {
  grid-column: span 2 / span 2;
}

.datepicker-header {
  display: flex;
  flex-direction: row;
  background-color: white;
  padding: 16px 16px;
}

.datepicker-header h4 {
  flex-grow: 1;
  text-align: start;
  height: 24px;
  margin: 2px 0 0 0;
}
.datepicker-header button {
  margin: 0;
  padding: 0 16px;
}
.datepicker-special-day {
  width: 58px;
  height: 17px;
  margin: 36px 0 0;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: var(--text-secondary);
}

.react-datepicker__header {
  border: none;
}
.react-datepicker__day-names {
  background-color: white;
}
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  width: auto;
  min-width: 12vw;
  margin: 3px;
}
@media (min-width: 570px) {
  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    min-width: 1.7rem;
  }
  .datepicker-header h4 {
    text-align: center;
  }
}
.react-datepicker__day-names,
.react-datepicker__week {
  display: grid;
  grid-template-columns: repeat(7, minmax(0, 1fr));
}
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: var(--primary-color);
  color: var(--text-primary);
}
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: var(--primary-color);
  color: var(--text-primary);
}
.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
.react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
.react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
.react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range) {
  background-color: #fff4e5;
  color: var(--text-primary);
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
  background-color: #fff4e5;
}


.calendar-wrapper {
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
  background-color: white;
  border-radius: 12px;
  overflow: hidden;
  z-index: 5;
}

.calendar-wrapper .additional-views {
  padding: 24px 29px;
}

.calendar-wrapper .button-wrapper button {
  min-width: 150px;
}

.calendar-wrapper .button-wrapper button + button {
  margin-left: 22px;
}

@media (max-width: 794px) {
  .calendar-wrapper .additional-views {
    padding: 16px;
  }
  .calendar-wrapper .button-wrapper button {
    min-width: 100px;
    font-size: 14px;
  }
  .rdrMonth {
    width: 100% !important;
  }

  .rdrInfiniteMonths {
    height: 100% !important;
  }
}
@media (max-width: 350px) {
  .calendar-wrapper .button-wrapper button {
    min-width: 60px;
    font-size: 12px;
  }
}