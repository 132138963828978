footer {
  background-color: white;
  border-top: solid 1px var(--line-color);
  padding: 29px;
}
footer p, footer a {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.64;
  letter-spacing: normal;
  text-align: center;
  color: var(--text-secondary);
}
footer em {
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--primary-color);
}

@media (max-width: 798px) {
  footer {
    padding: 32px 16px;
  }
  footer .container {
    padding-top: 0px !important;
  }
  footer p, footer a {
    margin: 0;
    font-size: 11px;
    text-align: center;
  }
}

@media (max-width: 570px) {
  footer {
    padding: 32px 16px 32px;
  }
  footer p, footer a {
    margin: 0;
    font-size: 11px;
    text-align: start;
  }

  footer p span:first-child {
    font-size: 14px;
    font-weight: 700;
  }
}