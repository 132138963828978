.tab1-header,
.tab2-header,
.tab3-header {
  margin: 56px 0 32px;
}
.tab1-header h3,
.tab1-header p,
.tab3-header h3,
.tab3-header p .tab2-header h3,
.tab2-header p {
  margin: 0 16px 24px;
}

.tab1-content {
  padding: 8px 18px 24px 18px;
  border: solid 1px var(--line-color);
  background-color: white;
  overflow: hidden;
  margin-bottom: 56px;
}
.tab1-content button {
  float: left;
  margin-top: 16px;
  margin-right: 16px;
  border-radius: 30px;
  padding-left: 44px;
  padding-right: 44px;
}
.tab1-content button.active {
  color: var(--text-primary);
  border: 1px solid var(--text-primary);
}
.tab1 .action {
  padding: 0 56px;
}
.ch-section {
  margin: 24px;
}
.ch-section > label {
  margin-right: 24px;
}
.tab1 .designs-holder {
  margin-bottom: 56px;
}

.tab3 .accordion-body {
  background-color: white;
  border: solid 1px var(--line-color);
  border-top-width: 0px;
}

.tab3 p.empty {
  display: inline-block;
  margin: 0;
  border: 0;
  padding: 16px;
  color: var(--text-secondary);
}

.tab3 .styles p {
  margin-right: 0;
  margin-left: 0;
}

.design-paper {
  padding: 0;
  margin: 0;
}
.design-paper p {
}
.design-paper:hover {
  background-color: #efefef;
}
.design-paper img {
  margin: 0;
}
.design-paper span.time {
  padding: 2px 8px;
  border-radius: 4px;
  background-color: var(--text-secondary);
  color: white;
  position: absolute;
  right: 8px;
  bottom: 8px;
}
.design-paper-modal {
  padding-bottom: 0px !important;
}
.design-paper-modal .secs {
  padding: 24px;
}
.design-paper-modal .secs-title {
  margin: 24px 24px 0;
}
.design-paper-modal .gray-box {
  float: left;
  margin-right: 24px;
  padding: 14px 19px;
  border-radius: 7px;
  background-color: var(--line-color);
}
.design-paper-modal button {
  margin-top: 30px !important;
}

@media (max-width: 794px) {
  .design-paper-modal .secs-title {
    margin: 16px 16px 0;
  }
  .design-paper-modal .secs {
    padding: 16px;
  }
  .design-paper-modal .gray-box {
    margin-right: 12px;
    padding: 10px 14px;
    font-size: 14px;
  }
}

.ad-campaign-result {
  margin-top: 100px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: white;
}
.ad-campaign-result .header-section {
  padding: 24px 60px;
  background-color: #ffdeb4;

  font-size: 20px;
  font-weight: 900;
}
.ad-campaign-result .content-section {
  max-width: 552px;
  padding: 32px 0 0;
}
.ad-campaign-result .info-videos-section {
  padding: 32px 64px 0 64px;
}
.ad-campaign-result .carousel-inner {
  max-width: 552px;
}
.ad-campaign-result .loading-section {
  max-width: 552px;
  margin-bottom: 24px;
}
.ad-campaign-result .loading-section span {
  font-size: 18px;
  font-weight: bold;
  color: var(--text-primary);
}
.ad-campaign-result .action-section {
  margin: 40px 0 24px;
  max-width: 552px;
}
.result-note {
  background-color: var(--bg-color-1);
  border-radius: 4px;
  padding: 17px;
  min-height: 80px;
  color: var(--text-primary);
  max-width: 552px;
  margin-bottom: 56px;
}
.result-note span:first-child {
  font-weight: bold;
}
.result-note > * {
  margin: 0;
}

@media (max-width: 1134px) {
  .ad-campaign-result .info-videos-section {
    padding: 32px 32px 0 32px;
  }
  .ad-campaign-result .arrow-wrapper {
    min-width: 64px;
  }
}

.ai-loading .progress {
  width: 378px;
  margin-bottom: 16px;
  height: 8px;
}
.ai-loading .progress .progress-bar {
  padding-top: 8px;
}
