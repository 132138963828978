@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    margin: 0;
    font-family: "Noto Sans KR", apple-system, BlinkMacSystemFont, "Segoe UI",
      Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
      "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
      "Noto Color Emoji", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }

  html,
  body {
    height: 100%;
    width: 100%;
    background-color: var(--bg-color-1);
  }

  div#root {
    min-height: 100%;
    min-width: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .flex-center {
    align-items: center;
    justify-content: center;
  }
}

.flex-center {
  justify-content: center;
  align-items: center;
}